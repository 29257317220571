import axios, { AxiosInstance } from "axios";

export class BeZkmService {
  protected instance: AxiosInstance;
  protected token: string | null = null;
  public baseURL?: string = "https://be-zkm.movidacloud.com.br/";

  public constructor(baseURL: string) {
    this.baseURL = this.getCorrectURL();
    if (baseURL.length > 0) {
      this.baseURL = baseURL;
    }
    this.instance = axios.create({
      baseURL: this.baseURL,
    });

    this.initializeRequestInterceptor();
  }

  getConfigHeaders(): object {
    return {
      headers: {
        Accept: "application/json",
        channel: 26,
      },
    };
  }

  getCorrectURL() {
    const currentURL = window.location.href;

    if (currentURL.includes(".dev")) {
      return "https://be-zkm-vs.dev.movidacloud.com.br/";
    } else if (currentURL.includes("localhost")) {
      return "http://be-0km.local/";
    } else {
      return "https://be-zkm.movidacloud.com.br/";
    }
  }

  private initializeRequestInterceptor() {
    this.autenticateJwt();
    this.instance.interceptors.request.use((config) => {
      config.headers["AccessToken"] = this.token;
      config.headers["Api-Key"] = "a197eb7caea32fe8edf48a72855504a5e85bdbaf";
      return config;
    });
  }

  private async autenticateJwt() {
    const route = `/api/v3/login`;
    const payload = {
      ct: "nDzrdiJwonfZgJ3vt42WJZVwzgj5WfX/kMxzQrKj86g=",
      iv: "0be82d5bd45199a27ae409599dadea1f",
      s: "3da3167d1bbe43f1",
    };
    const response = await this.post(route, payload);
    this.token = response.data.token;
  }

  /**
   * @param dataRequest
   * @param route
   */
  async put(route: string, dataRequest: object) {
    return await this.instance.put(route, dataRequest, this.getConfigHeaders());
  }

  /**
   * @param dataRequest
   * @param route
   */
  async post(route: string, dataRequest: object) {
    return await this.instance.post(route, dataRequest, this.getConfigHeaders());
  }

  /**
   * @param route
   */
  async get(route: string) {
    return await this.instance.get(route, this.getConfigHeaders());
  }

  /**
   * @param route
   */
  async delete(route: string) {
    return await this.instance.delete(route, this.getConfigHeaders());
  }
}
