const MOCK_CATALOG = {
  success: true,
  msg: "Listagem realizada com sucesso",
  data: [
    {
      id: 2,
      groupId: "JS",
      fipeCode: "002182-2",
      title: "Toyota Corolla",
      version: "Corolla Altis Hybrid 1.8 16V Flex Aut.",
      versionAndModel: "Corolla Altis",
      brand: "Toyota",
      model: "Corolla",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002182-2-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002182-2-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002182-2-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002182-2-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 4669.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 3,
      groupId: "JU",
      fipeCode: "024264-0",
      title: "Peugeot 208",
      version: "208 Like 1.0 Flex 6V 5p Mec.",
      versionAndModel: "208 Like",
      brand: "Peugeot",
      model: "208",
      tags: ["ENTREGA EM 48H"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/024264-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/024264-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/024264-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/024264-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 1999.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 4,
      groupId: "JY",
      fipeCode: "004515-2",
      title: "GM - Chevrolet Onix",
      version: "ONIX HATCH LTZ 1.0 12V TB Flex 5p Aut.",
      versionAndModel: "Onix HATCH",
      brand: "GM - Chevrolet",
      model: "Onix",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004515-2-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004515-2-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004515-2-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004515-2-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2449.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 6,
      groupId: "LT",
      fipeCode: "005542-5",
      title: "Volkswagen Virtus",
      version: "VIRTUS TSI 1.0 Flex 12V 4p Mec.",
      versionAndModel: "Virtus TSI",
      brand: "Volkswagen",
      model: "Virtus",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005542-5-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005542-5-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005542-5-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005542-5-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2419.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 7,
      groupId: "LV",
      fipeCode: "005543-3",
      title: "Volkswagen Virtus",
      version: "VIRTUS TSI 1.0 Flex 12V 4p Aut.",
      versionAndModel: "Virtus TSI",
      brand: "Volkswagen",
      model: "Virtus",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005543-3-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005543-3-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005543-3-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005543-3-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2569.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 8,
      groupId: "LW",
      fipeCode: "005544-1",
      title: "Volkswagen Virtus",
      version: "VIRTUS Exclusive 250TSI 1.4 Flex 16V Aut",
      versionAndModel: "Virtus Exclusive",
      brand: "Volkswagen",
      model: "Virtus",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005544-1-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005544-1-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005544-1-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005544-1-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3669.9,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 9,
      groupId: "NA",
      fipeCode: "005535-2",
      title: "Volkswagen Polo",
      version: "Polo Comfortline TSI 1.0 Flex 12V Aut.",
      versionAndModel: "Polo Comfortline",
      brand: "Volkswagen",
      model: "Polo",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005535-2-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005535-2-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005535-2-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005535-2-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2499.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 10,
      groupId: "NB",
      fipeCode: "005536-0",
      title: "Volkswagen Polo",
      version: "Polo Highline TSI 1.0 Flex 12V Aut.",
      versionAndModel: "Polo Highline",
      brand: "Volkswagen",
      model: "Polo",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005536-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005536-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005536-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005536-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2599.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 18,
      groupId: "NZ",
      fipeCode: "001558-0",
      title: "Fiat FASTBACK",
      version: "Fastback Audace 1.0 200 T. Flex Aut",
      versionAndModel: "FASTBACK Audace",
      brand: "Fiat",
      model: "FASTBACK",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001558-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001558-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001558-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001558-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3129.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 19,
      groupId: "OB",
      fipeCode: "001557-1",
      title: "Fiat FASTBACK",
      version: "Fastback Impetus 1.0 200 T. Flex Aut",
      versionAndModel: "FASTBACK Impetus",
      brand: "Fiat",
      model: "FASTBACK",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001557-1-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001557-1-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001557-1-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001557-1-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3419.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 20,
      groupId: "OR",
      fipeCode: "005540-9",
      title: "Volkswagen Polo",
      version: "POLO TRACK 1.0 FLEX 12V 5P",
      versionAndModel: "Polo TRACK",
      brand: "Volkswagen",
      model: "Polo",
      tags: ["OFERTA", "ENTREGA EM 48H"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005540-9-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005540-9-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005540-9-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005540-9-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 1939.8,
        pricePromotional: 0,
      },
      acessories: [
        {
          id: 2,
          name: "4 Malas",
        },
        {
          id: 11,
          name: "Ar Condicionado",
        },
        {
          id: 15,
          name: "R\u00e1dio",
        },
        {
          id: 19,
          name: "5 Passageiros",
        },
      ],
    },
    {
      id: 21,
      groupId: "OT",
      fipeCode: "011224-0",
      title: "Citroen C3",
      version: "C3 Feel 1.0 Flex 6V 5p Mec.",
      versionAndModel: "C3 Feel",
      brand: "Citroen",
      model: "C3",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/011224-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/011224-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/011224-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/011224-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 1999.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 27,
      groupId: "PJ",
      fipeCode: "001553-9",
      title: "Fiat Cronos",
      version: "CRONOS DRIVE 1.0 6V FLEX",
      versionAndModel: "Cronos DRIVE",
      brand: "Fiat",
      model: "Cronos",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001553-9-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001553-9-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001553-9-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001553-9-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2039.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 28,
      groupId: "PM",
      fipeCode: "011223-2",
      title: "Citroen C3",
      version: "C3 Live Pack 1.0 Flex 6V 5p Mec.",
      versionAndModel: "C3 Live",
      brand: "Citroen",
      model: "C3",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/011223-2-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/011223-2-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/011223-2-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/011223-2-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 1899.8,
        pricePromotional: 0,
      },
      acessories: [
        {
          id: 2,
          name: "4 Malas",
        },
        {
          id: 11,
          name: "Ar Condicionado",
        },
        {
          id: 19,
          name: "5 Passageiros",
        },
      ],
    },
    {
      id: 33,
      groupId: "QO",
      fipeCode: "025267-0",
      title: "Renault Kwid",
      version: "KWID Intense 1.0 Flex 12V 5p Mec.",
      versionAndModel: "Kwid Intense",
      brand: "Renault",
      model: "Kwid",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/025267-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/025267-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/025267-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/025267-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 1699.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 35,
      groupId: "QZ",
      fipeCode: "001556-3",
      title: "Fiat FASTBACK",
      version: "Fastback Limited Ed.1.3 270 T.Flex Aut.",
      versionAndModel: "FASTBACK Limited",
      brand: "Fiat",
      model: "FASTBACK",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001556-3-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001556-3-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001556-3-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001556-3-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3749.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 36,
      groupId: "RD",
      fipeCode: "001538-5",
      title: "Fiat Toro",
      version: "Toro Freedom 1.3 T270 4x2 Flex Aut.",
      versionAndModel: "Toro Freedom",
      brand: "Fiat",
      model: "Toro",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001538-5-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001538-5-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001538-5-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001538-5-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3869.7,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 37,
      groupId: "RL",
      fipeCode: "017081-0",
      title: "Jeep Commander",
      version: "Commander Limited T270 1.3 TB Flex Aut.",
      versionAndModel: "Commander Limited",
      brand: "Jeep",
      model: "Commander",
      tags: ["PREMIUM"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017081-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017081-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017081-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017081-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 5639.7,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 39,
      groupId: "SA",
      fipeCode: "005525-5",
      title: "Volkswagen NIVUS",
      version: "Nivus Comfortline 1.0 200 TSI Flex Aut.",
      versionAndModel: "NIVUS Comfortline",
      brand: "Volkswagen",
      model: "NIVUS",
      tags: ["OFERTA"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005525-5-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005525-5-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005525-5-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005525-5-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2869.8,
        pricePromotional: 0,
      },
      acessories: [
        {
          id: 11,
          name: "Ar Condicionado",
        },
        {
          id: 18,
          name: "5 Malas",
        },
        {
          id: 19,
          name: "5 Passageiros",
        },
      ],
    },
    {
      id: 41,
      groupId: "ST",
      fipeCode: "017088-7",
      title: "Jeep Renegade",
      version: "Renegade Long. T270 1.3 TB 4x2 Flex Aut.",
      versionAndModel: "Renegade Long.",
      brand: "Jeep",
      model: "Renegade",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017088-7-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017088-7-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017088-7-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017088-7-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3029.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 44,
      groupId: "TH",
      fipeCode: "005527-1",
      title: "Volkswagen TAOS",
      version: "TAOS Comfortline 1.4 250 TSI Flex Aut.",
      versionAndModel: "TAOS Comfortline",
      brand: "Volkswagen",
      model: "TAOS",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005527-1-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005527-1-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005527-1-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005527-1-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 4169.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 48,
      groupId: "UQ",
      fipeCode: "023178-9",
      title: "Nissan Kicks",
      version: "KICKS Sense 1.6 16V Flex Aut.",
      versionAndModel: "Kicks Sense",
      brand: "Nissan",
      model: "Kicks",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/023178-9-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/023178-9-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/023178-9-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/023178-9-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2569.8,
        pricePromotional: 0,
      },
      acessories: [
        {
          id: 11,
          name: "Ar Condicionado",
        },
        {
          id: 25,
          name: "Vidros e Travas El\u00e9tricas",
        },
        {
          id: 28,
          name: "Autom\u00e1tico",
        },
        {
          id: 36,
          name: "C\u00e2mera de r\u00e9",
        },
      ],
    },
    {
      id: 49,
      groupId: "US",
      fipeCode: "001542-3",
      title: "Fiat PULSE",
      version: "PULSE AUDACE 1.0 Turbo 200 Flex Aut.",
      versionAndModel: "PULSE AUDACE",
      brand: "Fiat",
      model: "PULSE",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001542-3-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001542-3-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001542-3-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001542-3-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2829.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 50,
      groupId: "UV",
      fipeCode: "002183-0",
      title: "Toyota Corolla",
      version: "Corolla Altis Prem. 1.8 Aut. H\u00edbrido",
      versionAndModel: "Corolla Altis",
      brand: "Toyota",
      model: "Corolla",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002183-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002183-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002183-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002183-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 4969.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 52,
      groupId: "VK",
      fipeCode: "001505-9",
      title: "Fiat Cronos",
      version: "CRONOS DRIVE 1.3 8V Flex",
      versionAndModel: "Cronos DRIVE",
      brand: "Fiat",
      model: "Cronos",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001505-9-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001505-9-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001505-9-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001505-9-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2169.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 53,
      groupId: "VM",
      fipeCode: "005528-0",
      title: "Volkswagen TAOS",
      version: "TAOS Highline 1.4 250 TSI Flex Aut.",
      versionAndModel: "TAOS Highline",
      brand: "Volkswagen",
      model: "TAOS",
      tags: ["PREMIUM"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005528-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005528-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005528-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005528-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 4529.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 54,
      groupId: "VT",
      fipeCode: "005508-5",
      title: "Volkswagen T-Cross",
      version: "T-Cross Highline 1.4 TSI Flex 16V 5p Aut",
      versionAndModel: "T-Cross Highline",
      brand: "Volkswagen",
      model: "T-Cross",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005508-5-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005508-5-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005508-5-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005508-5-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3569.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 55,
      groupId: "VV",
      fipeCode: "017071-2",
      title: "Jeep Compass",
      version: "COMPASS LONG. T270 1.3 TB 4x2 Flex Aut.",
      versionAndModel: "Compass LONG.",
      brand: "Jeep",
      model: "Compass",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017071-2-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017071-2-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017071-2-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017071-2-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 4169.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 56,
      groupId: "VY",
      fipeCode: "004501-2",
      title: "GM - Chevrolet Onix Plus",
      version: "ONIX SEDAN Plus LT 1.0 12V TB Flex Aut.",
      versionAndModel: "Onix Plus SEDAN",
      brand: "GM - Chevrolet",
      model: "Onix Plus",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004501-2-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004501-2-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004501-2-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004501-2-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2469.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 58,
      groupId: "WA",
      fipeCode: "005510-7",
      title: "Volkswagen T-Cross",
      version: "T-Cross 1.0 TSI Flex 12V 5p Aut.",
      versionAndModel: "T-Cross 1.0",
      brand: "Volkswagen",
      model: "T-Cross",
      tags: ["PACOTE INTERACTIVE", "ENTREGA EM 48H"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005510-7-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005510-7-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005510-7-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005510-7-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2969.8,
        pricePromotional: 0,
      },
      acessories: [
        {
          id: 5,
          name: "Sensores de Estacionamento",
        },
        {
          id: 35,
          name: "Sistema Multim\u00eddia 10,1''\"",
        },
        {
          id: 36,
          name: "C\u00e2mera de r\u00e9",
        },
        {
          id: 37,
          name: "Rodas de liga leve 17''",
        },
      ],
    },
    {
      id: 61,
      groupId: "XU",
      fipeCode: "001509-1",
      title: "Fiat Argo",
      version: "ARGO 1.0 6V Flex",
      versionAndModel: "Argo 1.0",
      brand: "Fiat",
      model: "Argo",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001509-1-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001509-1-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001509-1-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001509-1-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 1799.8,
        pricePromotional: 0,
      },
      acessories: [
        {
          id: 1,
          name: "3 Malas",
        },
        {
          id: 3,
          name: "4 Passageiros",
        },
        {
          id: 11,
          name: "Ar Condicionado",
        },
      ],
    },
    {
      id: 63,
      groupId: "YH",
      fipeCode: "004502-0",
      title: "GM - Chevrolet Onix Plus",
      version: "ONIX SEDAN Plus LTZ 1.0 12V TB Flex Aut.",
      versionAndModel: "Onix Plus SEDAN",
      brand: "GM - Chevrolet",
      model: "Onix Plus",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004502-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004502-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004502-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004502-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2529.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 65,
      groupId: "YS",
      fipeCode: "004525-0",
      title: "GM - Chevrolet Tracker",
      version: "TRACKER Premier 1.2 Turbo 12V Flex Aut.",
      versionAndModel: "Tracker Premier",
      brand: "GM - Chevrolet",
      model: "Tracker",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004525-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004525-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004525-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004525-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3669.9,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 66,
      groupId: "YW",
      fipeCode: "025266-2",
      title: "Renault Kwid",
      version: "KWID ZEN 1.0 FLEX 12V 5P MEC.",
      versionAndModel: "Kwid ZEN",
      brand: "Renault",
      model: "Kwid",
      tags: ["OFERTA", "ENTREGA EM 48H"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/025266-2-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/025266-2-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/025266-2-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/025266-2-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 1569.8,
        pricePromotional: 0,
      },
      acessories: [
        {
          id: 3,
          name: "4 Passageiros",
        },
        {
          id: 11,
          name: "Ar Condicionado",
        },
        {
          id: 15,
          name: "R\u00e1dio",
        },
        {
          id: 27,
          name: "Air Bag",
        },
      ],
    },
    {
      id: 67,
      groupId: "ZA",
      fipeCode: "001461-3",
      title: "Fiat Mobi",
      version: "MOBI Like 1.0 Fire Flex 5p.",
      versionAndModel: "Mobi Like",
      brand: "Fiat",
      model: "Mobi",
      tags: ["ENTREGA EM 48H"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001461-3-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001461-3-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001461-3-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001461-3-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 1699.8,
        pricePromotional: 0,
      },
      acessories: [
        {
          id: 11,
          name: "Ar Condicionado",
        },
        {
          id: 15,
          name: "R\u00e1dio",
        },
        {
          id: 27,
          name: "Air Bag",
        },
      ],
    },
    {
      id: 68,
      groupId: "ZD",
      fipeCode: "004518-7",
      title: "GM - Chevrolet Onix Plus",
      version: "ONIX SEDAN Plus LT 1.0 12V Flex 4p Mec.",
      versionAndModel: "Onix Plus SEDAN",
      brand: "GM - Chevrolet",
      model: "Onix Plus",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004518-7-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004518-7-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004518-7-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004518-7-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2249.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 70,
      groupId: "ZH",
      fipeCode: "005526-3",
      title: "Volkswagen NIVUS",
      version: "Nivus Highline 1.0 200 TSI Flex Aut.",
      versionAndModel: "NIVUS Highline",
      brand: "Volkswagen",
      model: "NIVUS",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005526-3-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005526-3-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005526-3-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005526-3-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3329.7,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 71,
      groupId: "ZJ",
      fipeCode: "005485-2",
      title: "Volkswagen Virtus",
      version: "VIRTUS Comfort. 200 TSI 1.0 Flex 12V Aut",
      versionAndModel: "Virtus Comfort.",
      brand: "Volkswagen",
      model: "Virtus",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005485-2-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005485-2-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005485-2-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005485-2-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2709.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 72,
      groupId: "ZN",
      fipeCode: "005509-3",
      title: "Volkswagen T-Cross",
      version: "T-Cross Comfortline 1.0 TSI Flex 5p Aut.",
      versionAndModel: "T-Cross Comfortline",
      brand: "Volkswagen",
      model: "T-Cross",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005509-3-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005509-3-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005509-3-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005509-3-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3199.8,
        pricePromotional: 0,
      },
      acessories: [
        {
          id: 11,
          name: "Ar Condicionado",
        },
        {
          id: 27,
          name: "Air Bag",
        },
        {
          id: 36,
          name: "C\u00e2mera de r\u00e9",
        },
      ],
    },
    {
      id: 73,
      groupId: "ZW",
      fipeCode: "001494-0",
      title: "Fiat Argo",
      version: "ARGO DRIVE 1.0 6V Flex",
      versionAndModel: "Argo DRIVE",
      brand: "Fiat",
      model: "Argo",
      tags: ["ENTREGA EM 48H"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001494-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001494-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001494-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001494-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 1899.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 74,
      groupId: "ZZ",
      fipeCode: "005484-4",
      title: "Volkswagen Virtus",
      version: "VIRTUS Highline 200 TSI 1.0 Flex 12V Aut",
      versionAndModel: "Virtus Highline",
      brand: "Volkswagen",
      model: "Virtus",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005484-4-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005484-4-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005484-4-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005484-4-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3229.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 128,
      groupId: "KC",
      fipeCode: "001544-0",
      title: "Fiat PULSE",
      version: "PULSE DRIVE 1.3 8V Flex Aut.",
      versionAndModel: "PULSE DRIVE",
      brand: "Fiat",
      model: "PULSE",
      tags: ["OFERTA", "ENTREGA R\u00c1PIDA"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001544-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001544-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001544-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001544-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2569.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 129,
      groupId: "PR",
      fipeCode: "004522-5",
      title: "GM - Chevrolet Tracker",
      version: "TRACKER LT 1.0 Turbo 12V Flex Aut.",
      versionAndModel: "Tracker LT",
      brand: "GM - Chevrolet",
      model: "Tracker",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004522-5-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004522-5-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004522-5-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004522-5-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2949.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 130,
      groupId: "SI",
      fipeCode: "023181-9",
      title: "Nissan Kicks",
      version: "KICKS Exclusive 1.6 16V Flex Aut.",
      versionAndModel: "Kicks Exclusive",
      brand: "Nissan",
      model: "Kicks",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/023181-9-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/023181-9-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/023181-9-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/023181-9-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3119.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 131,
      groupId: "JW",
      fipeCode: "017085-2",
      title: "Jeep Renegade",
      version: "Renegade Sport T270 4x2 Flex Aut.",
      versionAndModel: "Renegade Sport",
      brand: "Jeep",
      model: "Renegade",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017085-2-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017085-2-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017085-2-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/017085-2-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3169.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 132,
      groupId: "YC",
      fipeCode: "004517-9",
      title: "GM - Chevrolet Onix",
      version: "ONIX HATCH LT 1.0 12V Flex 5p Mec.",
      versionAndModel: "Onix HATCH",
      brand: "GM - Chevrolet",
      model: "Onix",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004517-9-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004517-9-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004517-9-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004517-9-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2209.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 133,
      groupId: "TD",
      fipeCode: "004514-4",
      title: "GM - Chevrolet Onix",
      version: "ONIX HATCH LT 1.0 12V TB Flex 5p Aut.",
      versionAndModel: "Onix HATCH",
      brand: "GM - Chevrolet",
      model: "Onix",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004514-4-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004514-4-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004514-4-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/004514-4-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2469.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 139,
      groupId: "HQ",
      fipeCode: "005540-9",
      title: "Volkswagen Polo",
      version: "POLO TRACK 1.0 FLEX 12V 5P",
      versionAndModel: "Polo TRACK",
      brand: "Volkswagen",
      model: "Polo",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005540-9-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005540-9-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005540-9-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005540-9-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 1999.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 142,
      groupId: "GD",
      fipeCode: "001569-5",
      title: "Fiat Strada",
      version: "Strada Endurance 1.3 Flex 8V CS",
      versionAndModel: "Strada Endurance",
      brand: "Fiat",
      model: "Strada",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001569-5-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001569-5-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001569-5-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001569-5-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2369.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 143,
      groupId: "HO",
      fipeCode: "015234-0",
      title: "Hyundai HB20",
      version: "HB20 Sense Plus 1.0 Flex 12V Mec.",
      versionAndModel: "HB20 Sense",
      brand: "Hyundai",
      model: "HB20",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015234-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015234-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015234-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015234-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 1909.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 144,
      groupId: "GF",
      fipeCode: "015236-6",
      title: "Hyundai HB20",
      version: "HB20 Comfort Plus 1.0 TB Flex 12V Aut.",
      versionAndModel: "HB20 Comfort",
      brand: "Hyundai",
      model: "HB20",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015236-6-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015236-6-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015236-6-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015236-6-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2469.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 151,
      groupId: "ZQ",
      fipeCode: "015190-4",
      title: "Hyundai Creta",
      version: "Creta Action 1.6 16V Flex Aut.",
      versionAndModel: "Creta Action",
      brand: "Hyundai",
      model: "Creta",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015190-4-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015190-4-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015190-4-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015190-4-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2669.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 156,
      groupId: "FA",
      fipeCode: "015244-7",
      title: "Hyundai Creta",
      version: "Creta Comfort Plus 1.0 TB 12V Flex Aut.",
      versionAndModel: "Creta Comfort",
      brand: "Hyundai",
      model: "Creta",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015244-7-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015244-7-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015244-7-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015244-7-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2999.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 157,
      groupId: "FB",
      fipeCode: "015243-9",
      title: "Hyundai CRETA",
      version: "Creta Limit. Safety 1.0 TB 12V Flex Aut.",
      versionAndModel: "CRETA Limit.",
      brand: "Hyundai",
      model: "CRETA",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015243-9-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015243-9-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015243-9-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015243-9-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3369.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 216,
      groupId: "GX",
      fipeCode: "015239-0",
      title: "Hyundai HB20S",
      version: "HB20S Comfort Plus 1.0 Flex 12V Mec.",
      versionAndModel: "HB20S Comfort",
      brand: "Hyundai",
      model: "HB20S",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015239-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015239-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015239-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015239-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2169.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 468,
      groupId: "EC",
      fipeCode: "095009-2",
      title: "BYD DOLPHIN",
      version: "Dolphin Plus El\u00e9trico",
      versionAndModel: "DOLPHIN Plus",
      brand: "BYD",
      model: "DOLPHIN",
      tags: ["EL\u00c9TRICO", "NOVIDADE"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/095009-2-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/095009-2-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/095009-2-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/095009-2-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 8769.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 607,
      groupId: "EO",
      fipeCode: "005510-7",
      title: "Volkswagen T-Cross",
      version: "T-Cross 1.0 TSI Flex 12V 5p Aut.",
      versionAndModel: "T-Cross 1.0",
      brand: "Volkswagen",
      model: "T-Cross",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005510-7-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005510-7-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005510-7-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/005510-7-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2869.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 780,
      groupId: "UM",
      fipeCode: "002203-9",
      title: "Toyota Corolla",
      version: "Corolla Cross XRE 2.0 16V Flex Aut.",
      versionAndModel: "Corolla Cross",
      brand: "Toyota",
      model: "Corolla",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002203-9-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002203-9-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002203-9-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002203-9-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 4069.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 781,
      groupId: "EP",
      fipeCode: "015237-4",
      title: "Hyundai Creta",
      version: "Creta Plat. Safety 1.0 TB 12V Flex Aut.",
      versionAndModel: "Creta Plat.",
      brand: "Hyundai",
      model: "Creta",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015237-4-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015237-4-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015237-4-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015237-4-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3749.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 831,
      groupId: "EQ",
      fipeCode: "096001-2",
      title: "GWM Haval",
      version: "Haval H6 Premium HEV",
      versionAndModel: "Haval H6",
      brand: "GWM",
      model: "Haval",
      tags: ["PREMIUM", "NOVIDADE"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096001-2-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096001-2-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096001-2-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096001-2-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 8069.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 832,
      groupId: "YU",
      fipeCode: "002111-3",
      title: "Toyota Corolla",
      version: "Corolla XEi 2.0 Flex 16V Aut.",
      versionAndModel: "Corolla XEi",
      brand: "Toyota",
      model: "Corolla",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002111-3-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002111-3-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002111-3-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002111-3-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 3769.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 833,
      groupId: "ES",
      fipeCode: "095005-0",
      title: "BYD Song",
      version: "Song Plus (Hibrido)",
      versionAndModel: "Song Plus",
      brand: "BYD",
      model: "Song",
      tags: ["PREMIUM", "NOVIDADE"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/095005-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/095005-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/095005-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/095005-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 8869.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1007,
      groupId: "ER",
      fipeCode: "001536-9",
      title: "Fiat Mobi",
      version: "MOBI TREKKING 1.0 Flex 5p.",
      versionAndModel: "Mobi TREKKING",
      brand: "Fiat",
      model: "Mobi",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001536-9-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001536-9-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001536-9-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001536-9-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 1749.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1183,
      groupId: "DG",
      fipeCode: "023180-0",
      title: "Nissan Kicks",
      version: "KICKS Advance 1.6 16V Flex Aut.",
      versionAndModel: "Kicks Advance",
      brand: "Nissan",
      model: "Kicks",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/023180-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/023180-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/023180-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/023180-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2869.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1236,
      groupId: "DN",
      fipeCode: "015235-8",
      title: "Hyundai HB20",
      version: "HB20 Limited Plus1.0 Flex 12V Mec.",
      versionAndModel: "HB20 Limited",
      brand: "Hyundai",
      model: "HB20",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015235-8-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015235-8-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015235-8-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015235-8-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2119.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1286,
      groupId: "DP",
      fipeCode: "015242-0",
      title: "Hyundai HB20S",
      version: "HB20S Limited Plus 1.0  Flex 12V Mec.",
      versionAndModel: "HB20S Limited",
      brand: "Hyundai",
      model: "HB20S",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015242-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015242-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015242-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015242-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2269.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1301,
      groupId: "GV",
      fipeCode: "015240-4",
      title: "Hyundai HB20S",
      version: "HB20S Comfort Plus 1.0 TB Flex 12V Aut",
      versionAndModel: "HB20S Comfort",
      brand: "Hyundai",
      model: "HB20S",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015240-4-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015240-4-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015240-4-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015240-4-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2639.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1401,
      groupId: "DQ",
      fipeCode: "015238-2",
      title: "Hyundai HB20S",
      version: "HB20S Plat. Safety 1.0 TB Flex 12V Aut.",
      versionAndModel: "HB20S Plat.",
      brand: "Hyundai",
      model: "HB20S",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015238-2-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015238-2-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015238-2-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015238-2-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2859.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1451,
      groupId: "FG",
      fipeCode: "015245-5",
      title: "Hyundai HB20",
      version: "HB20 Hatch Comfort Plus 1.0 Mec Flex",
      versionAndModel: "HB20 Hatch",
      brand: "Hyundai",
      model: "HB20",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015245-5-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015245-5-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015245-5-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/015245-5-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2019.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1501,
      groupId: "UC",
      fipeCode: "001530-0",
      title: "Fiat Strada",
      version: "Strada Freedom 1.3 Flex 8V CD",
      versionAndModel: "Strada Freedom",
      brand: "Fiat",
      model: "Strada",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001530-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001530-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001530-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/001530-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 2529.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1604,
      groupId: "UX",
      fipeCode: "002204-7",
      title: "Toyota Corolla",
      version: "Corolla Cross XRX 1.8 16V Aut.(H\u00edbrido)",
      versionAndModel: "Corolla Cross",
      brand: "Toyota",
      model: "Corolla",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002204-7-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002204-7-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002204-7-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002204-7-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 5069.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1605,
      groupId: "UW",
      fipeCode: "002200-4",
      title: "Toyota Corolla",
      version: "Corolla Cross XRV 1.8 16V Aut.(H\u00edbrido)",
      versionAndModel: "Corolla Cross",
      brand: "Toyota",
      model: "Corolla",
      tags: [],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002200-4-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002200-4-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002200-4-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/002200-4-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 4869.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1655,
      groupId: "CE",
      fipeCode: "096006-3",
      title: "GWM Ora 03 Skin (El\u00e9trico)",
      version: "Ora 03 Skin (El\u00e9trico)",
      versionAndModel: "Ora 03 Skin (El\u00e9trico) 03",
      brand: "GWM",
      model: "Ora 03 Skin (El\u00e9trico)",
      tags: ["PREMIUM", "NOVIDADE"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096006-3-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096006-3-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096006-3-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096006-3-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 7869.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1705,
      groupId: "CB",
      fipeCode: "096002-0",
      title: "GWM Haval",
      version: "Haval H6 Premium PHEV AWD",
      versionAndModel: "Haval H6",
      brand: "GWM",
      model: "Haval",
      tags: ["PREMIUM", "NOVIDADE"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096002-0-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096002-0-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096002-0-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096002-0-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 10569.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1755,
      groupId: "CC",
      fipeCode: "096003-9",
      title: "GWM Haval",
      version: "Haval H6 GT PHEV AWD",
      versionAndModel: "Haval H6",
      brand: "GWM",
      model: "Haval",
      tags: ["PREMIUM", "NOVIDADE"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096003-9-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096003-9-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096003-9-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096003-9-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 12169.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
    {
      id: 1805,
      groupId: "CD",
      fipeCode: "096007-1",
      title: "GWM Ora 03 GT (El\u00e9trico)",
      version: "Ora 03 GT (El\u00e9trico)",
      versionAndModel: "Ora 03 GT (El\u00e9trico) 03",
      brand: "GWM",
      model: "Ora 03 GT (El\u00e9trico)",
      tags: ["PREMIUM", "NOVIDADE"],
      pictures: [
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096007-1-image_transparente.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096007-1-image_transparente-large.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096007-1-image_transparente-medium.webp",
        "https://static.movidazerokm.com.br/midias/zerokm/prod/vehicles-fipe/096007-1-image_transparente-small.webp",
      ],
      prices: {
        priceStarting: 9769.8,
        pricePromotional: 0,
      },
      acessories: [],
    },
  ],
};
export default MOCK_CATALOG;
