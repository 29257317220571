<template>
  <div style="border: 1px solid #a0a0a0, background-color: #f0f0f0, height: 32px, width: 100px;">
    <div class="content" :class="{ done: task?.done }">{{ task?.content }}</div>
    <div class="buttons">
      <button @click="toggleDone">{{ task?.done ? "Desfazer" : "Pronto" }}</button>
      <button @click="removeTask" class="delete">Apagar</button>
    </div>
    <br />
  </div>
</template>
<script lang="ts">
import { Task } from "@/models/task/Task";
import { Vue, prop } from "vue-class-component";

class Props {
  task?: Task;
  withDefault = prop({ default: "default" });
}

export default class TaskComponent extends Vue.with(Props) {
  toggleDone(): void {
    this.$store.commit("TOGGLE_TASK", this.task);
  }
  removeTask(): void {
    this.$store.commit("REMOVE_TASK", this.task);
  }
}
</script>
<style>
.done {
  text-decoration: underline overline #ff3028;
  background-color: aquamarine;
}
</style>
