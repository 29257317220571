<template>
  <v-container class="d-flex flex-column">
    <header class="d-flex align-end justify-space-between">
      <div class="col-sm-12 col-lg-6">
        <h3>Perfil</h3>
        <h2>{{ profileDescription.name }}</h2>
      </div>
      <nav class="col-sm-12 col-lg-6 d-flex profile-buttons">
        <a class="text-decoration-none">
          <v-btn class="text-white" color="grey" href="/profiles">
            <v-icon> mdi-plus </v-icon>
            Voltar
          </v-btn>
        </a>

        <a class="text-decoration-none">
          <v-btn class="text-white" color="orange" @click="update(true)">
            <v-icon> mdi-plus </v-icon>
            Salvar Alterações
          </v-btn>
        </a>
      </nav>
    </header>
  </v-container>
  <v-container>
    <fieldset>
      <v-container>
        <v-row>
          <v-col cols="2" md="3">
            <h4>Módulo para editar</h4>
          </v-col>
          <v-col cols="3" md="5">
            <v-select v-model="module" :items="modules" item-title="name" item-value="id" persistent-hint return-object single-line @update:modelValue="onChangeModule" :disabled="isLoading" />
          </v-col>
        </v-row>
      </v-container>
    </fieldset>
  </v-container>
  <v-container v-if="module.id !== 0 && functionalities.length > 0">
    <fieldset>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <h4 class="mb-2">Funcionalidades</h4>
              </v-col>
              <v-col cols="6">
                <v-text-field class="pt-2" label="Filtrar" prepend-inner-icon="mdi-magnify" clearable single-line density="compact" variant="solo" hide-details v-model="functionalityForSearch" @input="getFunctionalitiesByNeedle"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-switch v-model="showEnabledFunctionalities" label="Ativos" color="orange" hide-details @change="getAllFunctionalities(true)"></v-switch>
              </v-col>
            </v-row>
            <v-list destiny="compact" v-if="functionalities.length > 0">
              <v-list-item v-for="functionality in functionalities" :key="functionality.id" :value="functionality" active-color="primary" @click="getPermissionsByFunctionality(functionality)">
                <template v-slot:prepend>
                  <v-icon :icon="functionality.icon"></v-icon>
                  <v-list-item-title v-text="functionality.name"></v-list-item-title>
                </template>
              </v-list-item>
            </v-list>
          </v-col>
          <v-divider class="ms-3" inset vertical style="color: #3b4651; border-width: 1.5px"></v-divider>
          <v-col cols="5" v-if="functionalityName !== ''">
            <v-list destiny="compact" class="permission-list">
              <v-card>
                <v-checkbox v-model="functionalityIsSelected" readonly :label="functionalityName"></v-checkbox>
              </v-card>
              <v-list-item v-for="(permission, i) in functionalityPermissions" :key="i" :value="permission" active-color="primary">
                <v-switch class="switch-route" color="orange" :label="permission.title" v-model="permission.isEnabled" @change="updatePermission(permission)"></v-switch>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </fieldset>
  </v-container>
  <v-container class="d-flex align-center justify-center">
    <v-snackbar v-model="snackbar" :timeout="timeout">
      <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
      <template v-slot:actions>
        <v-btn variant="text" @click="snackbar = false"> Fechar </v-btn>
      </template>
    </v-snackbar>
  </v-container>
  <v-container v-if="isLoading">
    <div class="text-center">
      <v-progress-circular indeterminate color="orange"></v-progress-circular>
    </div>
  </v-container>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { Functionality } from "@/models/functionality/Functionality";
import { SubFunctionality } from "@/models/functionality/SubFunctionality";
import { FunctionalityPermission } from "@/models/functionality/FunctionalityPermission";
import { Permission } from "@/models/functionality/Permission";

@Options({
  components: {},
})
export default class profileUpdateView extends Vue {
  public rules = [(value: any) => !!value || "Required."];
  public $route: any;
  public profileId = 0;
  public profileDescription = {
    name: "",
    description: "",
    isEnabled: false,
  };
  public name = "";
  public modules = [{ id: 0 }];
  public module = { id: 0, name: "Selecione o módulo" };
  public functionalities: Functionality[] = [];
  public functionalityName = "";
  public functionalityIsSelected = false;
  public functionalityPermissions: FunctionalityPermission[] = [];
  public permissions: Permission[] = [];
  public showEnabledFunctionalities = true;
  public functionalityForSearch = "";
  public snackbar = false;
  public text = "";
  public timeout = 3000;
  public isLoading = false;
  mounted(): void {
    this.profileId = Number(this.$route.params.id);
    this.loadProfileDescription(this.profileId);
    this.getModules();
  }

  async loadProfileDescription(id: number) {
    try {
      const response = await services.ProfileService.view(id);
      this.profileDescription.name = response.results.name;
      this.profileDescription.description = response.results.description;
    } catch (e) {
      console.log(e);
      this.text = "Erro ao carregar perfil";
      this.snackbar = true;
    }
  }

  async getModules() {
    this.isLoading = true;
    try {
      const response = await services.ModuleService.getAllModulesWithoutPaginate();
      this.modules = response.results;
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.text = "Erro ao carregar os módulos";
      this.snackbar = true;
      this.isLoading = false;
    }
  }

  async getAllFunctionalities(reload = false) {
    if (!reload && this.functionalities.length > 0) {
      return;
    }
    this.functionalities = [];
    this.isLoading = true;
    const isEnabled = this.showEnabledFunctionalities ? "true" : "false";
    try {
      const response = await services.FunctionalityService.getParentFunctionalities(this.functionalityForSearch, isEnabled, "true");
      this.functionalities = response.results;
      await this.getAllPermissions(reload);
    } catch (e) {
      console.log(e);
      this.text = "Erro ao carregar as functionalidades";
      this.snackbar = true;
      this.isLoading = false;
    }
  }

  getFunctionalitiesByNeedle() {
    const previousValue = this.functionalityForSearch;
    setTimeout(() => {
      const conditionOne = this.functionalityForSearch.length > 2 || (this.functionalityForSearch.length === 0 && previousValue.length > 0);
      const conditionTwo = this.functionalityForSearch === previousValue;
      if (conditionOne && conditionTwo) {
        this.getAllFunctionalities(true);
      }
    }, this.timeout);
  }

  async getAllPermissions(reload = false) {
    if (!reload && this.permissions.length > 0) {
      return;
    }
    this.permissions = [];
    const response = await services.ProfileService.getPermissions(this.profileId);
    const permissions = Object.values(response.results.permissions);
    for (let i = 0; i < permissions.length; i++) {
      const modulePermissions: any = permissions[i];
      for (let j = 0; j < modulePermissions.length; j++) {
        if (modulePermissions[j].route) {
          this.permissions.push({ route_id: modulePermissions[j].route.id, module_id: this.modules[i].id });
        }
      }
    }
    this.isLoading = false;
  }

  resetToInitialState() {
    this.functionalityIsSelected = false;
    this.functionalityName = "";
    this.functionalityPermissions = [];
  }

  getPermissionsByFunctionality(functionality: any) {
    this.functionalityPermissions = [];
    this.functionalityName = functionality.name;
    this.functionalityIsSelected = true;
    const subFunctionalities = functionality.sub_functionalities;

    if (subFunctionalities.length > 0) {
      for (let i = 0; i < subFunctionalities.length; i++) {
        const subFunctionality: SubFunctionality = subFunctionalities[i];
        this.functionalityPermissions.push({
          title: subFunctionality.name,
          isEnabled: this.subFunctionalityHasRouteVerify(subFunctionality.route_id),
          route_id: subFunctionality.route_id,
          module_id: this.module.id,
        });
      }
    }
  }

  subFunctionalityHasRouteVerify(targetId: number): boolean {
    return this.permissions.some((permission: any) => {
      if (targetId === permission.route_id) {
        return true;
      }
    });
  }

  updatePermission(permission: FunctionalityPermission) {
    if (permission.isEnabled) {
      this.permissions.push({ route_id: permission.route_id, module_id: permission.module_id });
    } else {
      this.permissions = this.permissions.filter((item: any) => item.route_id !== permission.route_id);
    }
  }

  profileUpdateValidation(): boolean {
    return this.module.id > 0 && this.functionalities.length > 0 && this.permissions.length > 0;
  }

  async update(event: any): Promise<void> {
    const validation = this.profileUpdateValidation();

    if (!validation) {
      this.text = "Nenhum módulo foi selecionado!";
      this.snackbar = true;
      return;
    }

    if (!this.functionalityIsSelected) {
      this.text = "Nenhuma funcionalidade foi selecionada!";
      this.snackbar = true;
      return;
    }

    const permissionsToUpdate: { groups: { route_id: number; module_id: number }[] } = {
      groups: this.permissions,
    };

    try {
      const response = await services.ProfileService.updatePermission(permissionsToUpdate, this.profileId);
      this.text = response.message;
      this.snackbar = true;
    } catch (e) {
      console.log(e);
      this.text = "Houve um erro ao tentar atualizar as permissões!";
      this.snackbar = true;
    }
  }

  async onChangeModule(): Promise<void> {
    if (this.module.id === 0) {
      this.text = "Nenhum módulo selecionado";
      this.snackbar = true;
      return;
    }
    this.resetToInitialState();
    await this.getAllFunctionalities(true);
  }
}
</script>
<style lang="css">
h3 {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  margin-bottom: 20px;
}

.profile-buttons {
  gap: 15px;
}

fieldset {
  border: 1px solid var(--neutral-200);
  border-radius: 5px;
  color: #3b4651;
}
</style>
@/models/profile/ProfileStoreRequest
