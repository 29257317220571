<template>
  <v-form validate-on="submit lazy" @submit.prevent="store">
    <div class="d-flex justify-space-between">
      <h1 class="">Novo agendamentos</h1>
      <a class="text-decoration-none">
        <v-btn class="text-white" color="grey" href="/schedules">Cancelar</v-btn>
        <v-btn class="text-white" color="orange" type="submit" v-bind:disabled="btn_disabled">Salvar</v-btn>
      </a>
    </div>
    <div>
      <v-col sm="12" md="6">
        <v-autocomplete
          v-model="orderInfo"
          v-model:search="search"
          :rules="rulesRequired"
          :loading="loading || loadingOrder"
          :items="orders"
          item-title="label"
          item-value="id"
          density="comfortable"
          hide-no-data
          hide-details
          label="Pedido"
          variant="solo"
          append-inner-icon="mdi-magnify"
          @update:modelValue="selectBranch"
        ></v-autocomplete>
      </v-col>
      <v-col sm="12" md="6">
        <v-autocomplete v-model="branchId" label="Loja de Retirada" :loading="loading || loadindBranch" :rules="rulesRequired" :items="branchs" item-title="nome" item-value="id" variant="solo" @update:modelValue="getAvailability"></v-autocomplete>
      </v-col>
      <v-col sm="12" md="6">
        <v-autocomplete v-model="serviceId" label="Tipo de Agendamento" :loading="loading || loadingService" :rules="rulesRequired" :items="services" item-title="nome" item-value="servico_id" variant="solo" @update:modelValue="getAvailability"></v-autocomplete>
      </v-col>
      <v-col sm="12" md="6">
        <v-divider></v-divider>
      </v-col>
      <v-col sm="12" md="6">
        <VueDatePicker v-model="date" input-class-name="datepickerForm" locale="pt-br" select-text="selecionar" cancel-text="cancelar" :format="format" :enable-time-picker="false" :allowed-dates="allowedDates" @cleared="clearCalendar" @update:model-value="getHourAvailability"></VueDatePicker>
        <v-progress-linear indeterminate v-if="loading || loadingDate"></v-progress-linear>
      </v-col>
      <v-col sm="12" md="6">
        <v-select v-model="hour" :items="allowedHour" :loading="loading || loadingHour" variant="solo" :rules="rulesRequired" append-inner-icon="mdi-clock"></v-select>
      </v-col>
    </div>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
      <template v-slot:actions>
        <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import * as services from "@/services";
import { ScheduleStoreRequest } from "@/models/schedule/ScheduleStoreRequest";
import messages from "@/constants/messages";
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

@Options({
  components: {
    VueDatePicker,
  },
})
export default class ScheduleCreateView extends Vue {
  public rulesRequiredAndLength = [(value: any) => !!value || "Campo Obrigatorio.", (value: string) => (value && value.length >= 3) || "Min 3 characters"];
  public rulesRequired = [(value: any) => !!value || "Campo Obrigatório."];
  public messages = {};
  public sortSelected = "name";
  public loading = false;
  public loadingOrder = false;
  public loadingDate = false;
  public loadingHour = false;
  public loadingBranch = false;
  public loadingService = false;
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public btn_disabled = false;
  public branchs = [];
  public services = [];
  public allowedDates = [];
  public peopleId = null;
  public branchId = null;
  public serviceId = null;
  public date = null;
  public hour = null;
  public availability: any = {};
  public allowedHour = [];
  public orders: any = [];
  public orderInfo: any = null;
  public search = "teste";

  @Watch("search")
  onSearchChanged(val: string, oldVal: string) {
    if (val && Array.from(val).length > 3) {
      this.getOrders(val);
    }
  }

  mounted(): void {
    this.getBranchs();
    this.getServices();
    this.messages = messages;
  }

  async getOrders(searchNeedle: any) {
    this.loadingOrder = true;
    const response = await services.OrderService.getAll(searchNeedle);
    this.orders = [];
    for (const iterator of response.results.data) {
      this.orders.push({ id: { orderId: iterator.PedidoFrotaID, peopleId: iterator?.pessoa?.PessoaID, branchId: iterator?.FilialID, plate: iterator?.frota?.Placa, cpf: iterator?.pessoa?.CNPJ }, label: `${iterator.PedidoFrotaID} - ${iterator?.pessoa?.RazaoSocial}` });
    }
    this.loadingOrder = false;
  }
  async getBranchs() {
    this.loadingBranch = true;
    const response = await services.ScheduleService.getAllBranch();
    this.branchs = response.results;
    this.loadingBranch = false;
  }
  async getServices() {
    this.loadingService = true;
    const response = await services.ScheduleService.getAllService();
    this.services = response.results;
    this.loadingService = false;
  }
  async getAvailability() {
    this.loadingDate = true;
    if (!this.branchId || !this.serviceId) return;

    const response = await services.ScheduleService.availability(this.branchId, this.serviceId);

    this.availability = response.results;

    this.loadAllowedDates();
    this.loadingDate = false;
  }

  format(date: any) {
    return moment(String(date)).format("DD/MM/YYYY");
  }

  selectBranch() {
    this.branchId = this.orderInfo.branchId;
  }

  getHourAvailability(date: any) {
    this.loadingHour = true;
    const dateFormatted = moment(String(date)).format("YYYY-MM-DD");

    const availability = this.availability?.datas?.find(function (data: any) {
      return data.data == dateFormatted;
    });

    this.allowedHour = availability.intervalos.map(function (hour: any) {
      return hour.inicio;
    });
    this.loadingHour = false;
  }

  private loadAllowedDates() {
    this.allowedDates = this.availability?.datas?.map(function (data: any) {
      if (data.intervalos.length > 0) return data.data + " 00:00:00";
    });
  }

  changeLoading(): void {
    this.loading = !this.loading;
  }

  closeSnack(): void {
    this.snackbar = false;
    this.$router.push("/schedules");
  }

  async store(event: any): Promise<void> {
    this.changeLoading();
    const meta = {
      FilialID: this.orderInfo.branchId,
      PedidoFrotaID: this.orderInfo.orderId,
      Placa: this.orderInfo.plate,
      CpfCliente: this.orderInfo.cpf,
    };

    const date = moment(String(this.date)).format("YYYY-MM-DD");

    const storeRequest: ScheduleStoreRequest = {
      data: date + " " + this.hour,
      horario_config_id: this.availability?.servico?.horarios_config_id,
      pessoa_id: this.orderInfo.peopleId,
      metas: JSON.stringify(meta),
      cancelado: false,
    };

    const response = await services.ScheduleService.store(storeRequest);

    if (response.has_success) {
      this.text = messages.success;
      this.snackbar = true;
      this.btn_disabled = true;
    } else {
      this.snackbar = true;
      this.text = messages.fail;
    }
    this.changeLoading();
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}

.dp__input {
  box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
}

.datepickerForm {
  height: 59px;
}

.dp__action_select,
.dp__range_end,
.dp__range_start,
.dp__active_date {
  background-color: #ff9800 !important;
}
</style>
@/models/schedule/ScheduleStoreRequest
