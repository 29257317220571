import { Menu } from "@/models/menu/Menu";
import routes from "@/constants/routes";
import store from "@/store";
import Modules from "./Modules";

export default class Funcionalities {
  public items: Array<Menu> = [];
  private modulesClass = new Modules();

  getFuncionalitiesMenu(): void {
    const moduleSelectedId = this.modulesClass.getModuleid();
    const obj = this.modulesClass.getModules();
    const objParse = JSON.parse(obj!);

    if (objParse) {
      const functionalities = objParse.find((x: any) => x.id == moduleSelectedId);

      const functionalitiesFiltered = functionalities.functionalities
        .filter((x: any) => x.is_menu) // Filtra apenas as funcionalidades com is_menu igual a true
        .sort((a: any, b: any) => a.menu_order - b.menu_order) //Ordenação por menu_order ASC
        .map((x: any) => {
          return { text: x.name, icon: x.icon, href: this.getRoute(x.route.name) };
        });
      this.items = functionalitiesFiltered;

      this.saveFuncionalitiesMenuStore();
    }
  }

  saveFuncionalitiesMenuStore(): void {
    store.commit("SET_MENU_LIST", this.items);
  }

  getRoute(routeName: string) {
    const { url } = Object.values(routes).find(({ name }) => name === routeName) || { url: "" };

    return url;
  }
}
