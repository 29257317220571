import { BaseService } from "../BaseService";

class OrderService extends BaseService {
  async getAll(orderId: string | null = null, page = 1, sortColumn: string | null = "name") {
    let route = `/api/v1/order?page=${page}&column=${sortColumn}`;
    if (orderId && Array.from(orderId).length > 3) {
      route = route + `&order_id=${orderId}`;
    }
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async getOrderInfo(orderId: any) {
    const route = `/api/v1/order/${orderId}`;
    const response = await this.get(route);
    return response.data;
  }
}

export default new OrderService("", "");
