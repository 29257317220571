import { BaseService } from "../../BaseService";
import { BeZkmService } from "../../BeZkmService";
import MOCK_CATALOG from "@/services/catalog/be/mocks/MOCK_CATALOG";

const beZkmServiceInstance = new BeZkmService("");
class CatalogService extends BaseService {
  private USE_MOCK = true;
  async getAllCatalog() {
    if (!this.USE_MOCK) {
      const route = `api/v4/catalog`;
      const response = await beZkmServiceInstance.get(route);
      return response.data;
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(MOCK_CATALOG.data);
        }, 500); // Simula um atraso de 500 ms
      });
    }
  }
}

export default new CatalogService("", "");
