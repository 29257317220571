<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Atualizar Funcionalidade</h1>
    <a class="text-decoration-none">
      <v-btn class="me-4 text-white" color="grey" href="/functionalities" rounded="lg">
        {{ messages.cancelar }}
      </v-btn>
      <v-btn class="text-white" color="orange" @click="update">
        <v-icon> mdi-plus</v-icon>
        Salvar
      </v-btn>
    </a>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      <v-icon class="ml-1">mdi-information-outline</v-icon>
      {{ text }}
      <template v-slot:actions>
        <v-btn variant="text" @click="snackbar = false">Fechar</v-btn>
      </template>
    </v-snackbar>
  </div>
  <div>
    <v-col cols="12" md="6">
      <v-text-field v-model="nome" @focus="reset" label="Nome" required :rules="rules.nome" :menu-props="{ bottom: true, offsetY: true }"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-select v-model="route_id" :items="routes" label="Rota de Acesso" item-title="name" item-value="id" @input="setSelected($event)" :rules="[(v) => !!v || 'Gender is required']" required></v-select>
    </v-col>
    <v-col cols="12" md="6">
      <v-select v-model="functionality_id" :items="functionalities" label="Categoria pai" clearable item-title="name" item-value="id" persistent-hint @input="setSelected($event)"></v-select>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field type="number" v-model="menuOrder" label="Ordem" required min="0" :rules="rules.menuOrder" :menu-props="{ bottom: true, offsetY: true }"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-combobox v-model="iconMenu" :items="icons" label="Icone de exibição" required></v-combobox>
      <v-icon :icon="iconMenu" v-if="iconMenu"></v-icon>
    </v-col>
    <v-col cols="12" md="6">
      <v-switch v-model="isMenu" label="Exibir como item de menu" color="orange" hide-details></v-switch>
    </v-col>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { FunctionalityStoreRequest } from "@/models/functionality/FunctionalityStoreRequest";
import messages from "@/constants/messages";
import IconsUtil from "@/utils/icons";

@Options({
  components: {},
})
export default class FuncionalidadeCreateView extends Vue {
  public rules = {
    nome: [(value: string) => !!value || "Required.", (value: string) => (value && value.length >= 3) || "Min 3 characters"],
    menuOrder: [(value: number) => !!value || "Item is required.", (v: number) => v >= 0 || "Item must be greater than 0"],
  };
  public icons: string[] = IconsUtil.getIcons();
  $route: any;
  public text = "";
  public timeout = 10000;
  public snackbar = false;
  public messages = {};
  public nome = "";
  public route_id = null;
  public functionality_id = null;
  public sortSelected = "name";
  public iconMenu = "";
  public isLoadingTable = true;
  public isMenu = false;
  public menuOrder = 1;
  public needleSearch = null;
  public routes = [];
  public functionalities = [];
  public id: any = 0;

  mounted(): void {
    this.id = Number(this.$route.params.id);
    this.getFunctionality(this.id);
    this.getFuncionalities();
    this.getRoutes();
    this.messages = messages;
  }

  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  async update(event: any): Promise<void> {
    const storeRequest: FunctionalityStoreRequest = {
      name: this.nome,
      route_id: this.route_id,
      functionality_id: this.functionality_id,
      icon: this.iconMenu,
      is_menu: this.isMenu,
      menu_order: this.menuOrder,
    };
    try {
      await services.FunctionalityService.updateFunctionality(storeRequest, this.id);
      this.text = "Alterações salvas com sucesso!";
      setTimeout(() => {
        this.$router.push("/functionalities");
      }, 3000);
    } catch (error: any) {
      this.text = error.message;
    }
    this.snackbar = true;
  }

  async getFuncionalities() {
    const response = await services.FunctionalityService.getAllFunctionalitiesWithoutPaginate();
    this.functionalities = response.results;
  }

  async getRoutes() {
    const response = await services.RouteService.getAllRoutesWithoutPaginate(true);
    this.routes = response.results;
  }

  async getFunctionality(funcionalityId: number) {
    try {
      const response = await services.FunctionalityService.getFunctionality(funcionalityId);

      this.nome = response.results.name;
      this.route_id = response.results.route_id;
      this.functionality_id = response.results.functionality_id;
      this.iconMenu = response.results.icon;
      this.isMenu = response.results.is_menu;
      this.menuOrder = response.results.menu_order;
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}

.v-icon.v-icon::after {
  border-radius: 8px;
}
</style>
