import { BaseService } from "../BaseService";
import { ModuleStoreRequest } from "@/models/module/ModuleStoreRequest";
import { ModuleIndexWithPaginateResponse } from "@/models/module/ModuleIndexWithPaginateResponse";
class ModuleService extends BaseService {
  async getAllModules(page = 1, needle: string | null = null, sortColumn: string | null = "name") {
    let route = `/api/v1/modules?page=${page}&order=ASC&column=${sortColumn}`;
    if (needle) {
      route = route + `&search=${needle}`;
    }
    const response = await this.instance.get<ModuleIndexWithPaginateResponse>(route, this.getConfigHeaders());
    return response.data;
  }
  async getAllModulesWithoutPaginate() {
    const route = `/api/v1/modules?without_paginate=true`;
    const response = await this.get(route);
    return response.data;
  }
  async storeNewModule(dataRequest: ModuleStoreRequest) {
    const route = `/api/v1/modules`;
    const response = await this.post(route, dataRequest);

    return response.data;
  }
  async getModule(moduleIdentification: number) {
    const route = `/api/v1/modules/${moduleIdentification}`;
    const response = await this.get(route);
    return response.data;
  }
  // async getModule1(moduleIdentification: number) {
  //   const route = `/api/v1/modules/${moduleIdentification}`;
  //   const response = this.instance.get(route, this.getConfigHeaders());
  //   console.log("response", response);
  //   return response;
  // }
  async updateModule(dataRequest: ModuleStoreRequest, moduleIdentification: number) {
    const route = `/api/v1/modules/${moduleIdentification}`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }
}
export default new ModuleService("", "");
