<template>
  <router-view />
</template>
<script lang="ts">
import { Vue } from "vue-class-component";

export default class App extends Vue {
  mounted() {
    // console.log("App will mount");
  }
}
</script>
<style></style>
