import { BaseService } from "../../BaseService";
import { CatalogIndexWithPaginateResponse } from "@/models/catalog/CatalogIndexWithPaginateResponse";

import { CatalogTagStoreRequest } from "@/models/catalogTag/CatalogTagStoreRequest";
class CatalogTagService extends BaseService {
  async store(dataRequest: CatalogTagStoreRequest) {
    const route = `/api/v1/catalogTag`;
    const response = await this.post(route, dataRequest);
    return response.data;
  }

  async getCatalogTags() {
    const route = `/api/v1/catalogTags`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }
}
export default new CatalogTagService("", "");
