<template>
  <div>
    <div class="logo">
      <img alt="Logo Movida" src="@/assets/brand-light.svg" />
    </div>
    <div class="d-flex justify-center align-center min-h-100vh">
      <v-flex class="justify-center">
        <v-col>
          <v-card elevation="5" class="pa-md-16 pa-sm-4 px-md-16 px-sm-4 rounded-lg">
            <v-row no-gutters>
              <v-container>
                <h1 class="text-left text-neutral-900">Token MFA</h1>
                <p class="text-neutral-600"> Insira o código de verificação que você recebeu:</p>
                <div class="mt-2 d-flex flex-column w-100">
                  <v-text-field v-model="verificationCode" label="Insira o Token MFA" dense outlined hide-details style="margin-bottom: 50px" maxlength="6"></v-text-field>
                  <v-btn color="movida-orange" size="large" block @click="verifyCode"> Acessar </v-btn>
                </div>
                <div v-if="showMessage" class="message-container">
                  <div :class="['message-balloon', messageColor]">{{ msgToken }}</div>
                </div>
              </v-container>
            </v-row>
          </v-card>
        </v-col>
      </v-flex>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";

@Options({
  components: {},
})
export default class Mfa extends Vue {
  public verificationCode!: number;
  public token!: number;
  public msgToken: string | undefined = "";
  public showMessage = false;
  public messageColor = "success";

  async mounted() {
    const metodoPadrao = localStorage.getItem("MP");

    if (metodoPadrao !== null && metodoPadrao === "1") {
      this.msgToken = "Token enviado por e-mail";
    }

    if (metodoPadrao !== null && metodoPadrao === "2") {
      this.msgToken = "Token enviado por sms";
    }

    if (metodoPadrao !== null && metodoPadrao === "3") {
      this.msgToken = "Acesse o seu aplicativo e digite o token";
    }

    if (metodoPadrao !== null && metodoPadrao === "4") {
      this.msgToken = "Token enviado por WhatsApp";
    }

    if (this.msgToken) {
      this.messageColor = "success";
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 7000);
    }
  }

  async verifyCode() {
    this.token = this.verificationCode;
    const TokenResponse = await services.AuthService.getInstance("").validateToken(this.token);

    if (TokenResponse.status) {
      this.$router.push("select-module");
    } else {
      this.messageColor = "error";
      this.msgToken = TokenResponse.message;
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 7000);
    }
  }
}
</script>

<style scoped>
.message-container {
  margin-top: 20px;
}

.message-balloon {
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.success {
  background-color: #03ae39;
}

.error {
  background-color: #ff0000;
}
</style>
