import { BaseService } from "../BaseService";
import { UserStoreRequest } from "@/models/user/UserStoreRequest";
import { UserIndexWithPaginateResponse } from "@/models/user/UserIndexWithPaginateResponse";

class UserService extends BaseService {
  /**
   * index
   * @param page
   * @param needle
   * @param sortColumn
   */
  async index(page = 1, needle: string | null = null, sortColumn: string | null = "name") {
    let route = `/api/v1/users?page=${page}&order=ASC&column=${sortColumn}`;
    if (needle) {
      route = route + `&search=${needle}`;
    }
    const response = await this.instance.get<UserIndexWithPaginateResponse>(route, this.getConfigHeaders());
    return response.data;
  }

  /**
   * view
   * @param Identification
   */
  async view(Identification: number) {
    const route = `/api/v1/users/${Identification}`;
    const response = await this.get(route);
    return response.data;
  }

  /**
   * store
   * @param dataRequest
   */
  async store(dataRequest: UserStoreRequest) {
    const route = `/api/v1/users`;
    const response = await this.post(route, dataRequest);
    return response.data;
  }

  /**
   * update
   * @param dataRequest
   * @param Identification
   */
  async update(dataRequest: UserStoreRequest, Identification: number) {
    const route = `/api/v1/users/${Identification}`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }
  /**
   * index typeApplication
   * @param page
   * @param needle
   * @param sortColumn
   */
  async typeApplication(page = 1, needle: string | null = null, sortColumn: string | null = "name") {
    let route = `/api/v1/users-type-application?page=${page}&orderBy=${sortColumn}&sortedBy=asc`;
    if (needle) {
      route = route + `&search=${needle}`;
    }
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }
  /**
   * tokenGenerate
   * @param dataRequest
   */
  async generateToken(dataRequest: any) {
    const route = `/api/v1/app/oauth/token`;
    const response = await this.post(route, dataRequest);
    return response.data;
  }

  /**
   *
   * @param Identification
   */
  async viewApp(Identification: number) {
    const route = `/api/v1/applications/${Identification}`;
    const response = await this.get(route);
    return response.data;
  }

  async updateApp(dataRequest: any, Identification: number) {
    const route = `/api/v1/applications/${Identification}`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }
}
export default new UserService("", "");
