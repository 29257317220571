import { BaseService } from "../BaseService";
import { FunctionalityStoreRequest } from "@/models/functionality/FunctionalityStoreRequest";
class FunctionalityService extends BaseService {
  async getAllFunctionalities(page = 1, needle: string | null = null, sortColumn: string | null = "name") {
    let currentRoute = `/api/v1/functionalities?page=${page}&column=${sortColumn}`;
    if (needle) {
      currentRoute = `${currentRoute}&search=${needle}`;
    }
    const response = await this.get(currentRoute);
    return response.data;
  }
  async getAllFunctionalitiesWithoutPaginate(needle: string | null = null, isEnabled: string | null = null) {
    let route = `/api/v1/functionalities?without_paginate=true`;
    if (needle) {
      route = `${route}&search=${needle}`;
    }
    if (isEnabled) {
      route = `${route}&isEnabled=${isEnabled}`;
    }
    const response = await this.get(route);
    return response.data;
  }
  async getParentFunctionalities(needle: string | null = null, isEnabled: string | null = null, pagination = "false") {
    let currentRoute = `/api/v1/functionalities/show/parents?without_paginate=${pagination}`;
    if (needle) {
      currentRoute = `${currentRoute}&search=${needle}`;
    }
    if (isEnabled) {
      currentRoute = `${currentRoute}&isEnabled=${isEnabled}`;
    }
    const response = await this.get(currentRoute);
    return response.data;
  }
  async storeNewFunctionality(dataRequest: FunctionalityStoreRequest) {
    const currentRoute = `/api/v1/functionalities`;
    const response = await this.post(currentRoute, dataRequest);
    return response.data;
  }
  async updateFunctionality(dataRequest: FunctionalityStoreRequest, functionalityIdentification: number) {
    const route = `/api/v1/functionalities/${functionalityIdentification}`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }
  async getFunctionality(functionalityIdentification: number) {
    const route = `/api/v1/functionalities/${functionalityIdentification}`;
    const response = await this.get(route);
    return response.data;
  }
}
export default new FunctionalityService("", "");
