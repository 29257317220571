import { BaseService } from "../BaseService";
import { AccessKeyStoreRequest } from "@/models/access-key/AccessKeyStoreRequest";
import { ModuleIndexWithPaginateResponse } from "@/models/module/ModuleIndexWithPaginateResponse";

class AccessKeyService extends BaseService {
  async getAllAccessKey(page = 1, needle: string | null = null, sortColumn: string | null = "name") {
    let route = `/api/v1/access-key?page=${page}&order=ASC&column=${sortColumn}`;
    if (needle) {
      route = route + `&search=${needle}`;
    }
    const response = await this.instance.get<ModuleIndexWithPaginateResponse>(route, this.getConfigHeaders());
    return response.data;
  }
  async getAllAccessKeyWithoutPaginate() {
    const route = `/api/v1/access-key?without_paginate=true`;
    const response = await this.get(route);
    return response.data;
  }
  async storeNewAccessKey(dataRequest: AccessKeyStoreRequest) {
    const route = `/api/v1/access-key`;
    const response = await this.post(route, dataRequest);

    return response.data;
  }
  async getAccessKey(accessKeyIdentification: number) {
    const route = `/api/v1/access-key/${accessKeyIdentification}`;
    const response = await this.get(route);
    return response.data;
  }
  async updateAccessKey(dataRequest: AccessKeyStoreRequest, accessKeyIdentification: number) {
    const route = `/api/v1/access-key/${accessKeyIdentification}`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }
}
export default new AccessKeyService("", "");
