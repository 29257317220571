<template>
  <div>
    <v-container>
      <v-row justify="space-between">
        <v-col>
          <h1>Vitrine</h1>
          <h5 class="text-grey lighten-1"> Canal {{ state.cluster.sales_channel_id }}, Mínimo {{ state.cluster.min }}, Máximo {{ state.cluster.max }} </h5>
        </v-col>
        <v-col cols="auto">
          <v-btn class="text-white btn-cancel elevation-0" color="grey" href="/clusters" :disabled="state.loading">
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn color="orange" class="text-white elevation-0" @click="toSave" :disabled="state.loading || !isFormValid">
            <v-icon>mdi-plus</v-icon>
            Salvar
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-container>
    <v-container>
      <v-card-title class="pa-0" v-if="!state.loading">{{ state.cluster.name }}</v-card-title>
      <v-alert v-if="state.serviceError" type="error" class="mt-5 mb-5"> Ocorreu um erro ao carregar os dados - {{ state.text }}</v-alert>
      <template v-if="state.loading && state.text">
        <v-sheet class="d-flex align-center justify-center pt-3 pb-3">
          <v-chip :color="state.serviceError ? 'red' : ''"> {{ state.text }} </v-chip>
        </v-sheet>
      </template>
      <h5 class="pb-2 text-grey lighten-1" v-if="filteredItems.length && !state.loading">Arraste para alterar a posição dos grupos</h5>
      <v-row v-if="filteredItems.length && !state.loading">
        <v-col>
          <v-btn class="text-white mb-5 mt-5 elevation-0 flex-md-row" color="grey" @click="toClean" :disabled="state.loading">
            <v-icon>mdi-close-circle-outline</v-icon>
            Limpar Vitrine
          </v-btn>
          <draggable class="dragArea list-group w-full" v-model="filteredItems" @end="onDragEnd">
            <div class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center" v-for="(element, index) in filteredItems" :key="element.name">
              <span class="position">{{ index + 1 + "º" }}</span>
              <v-icon>mdi-drag</v-icon>
              {{ element.name }}
            </div>
          </draggable>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row v-if="!state.loading">
        <v-col>
          <v-select v-model="state.selectedItemNames" :items="itemNames" label="Selecionar Itens" multiple chips :menu-props="{ closeOnContentClick: false }" :rules="[selectionRule()]" ref="selectInput">
            <template v-slot:selection="data">
              <v-chip :key="data.item" close @click:close="removeItem(data.item)">
                {{ data.item }}
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { VueDraggableNext } from "vue-draggable-next";
import * as services from "@/services";
import { ClusterStoreRequest } from "@/models/cluster/ClusterStoreRequest";
import { IProduct } from "@/models/cluster/Cluster";
import { ICatalog } from "@/models/catalog/Catalog";

interface Item {
  name: string;
}

interface State {
  allItems: Array<ICatalog>;
  items: Array<Item>;
  selectedItemNames: Array<string>;
  serviceError: boolean;
  loading: boolean;
  text: string;
  cluster: ClusterStoreRequest;
  timeout: number;
  products?: Array<IProduct>;
}

@Options({
  components: {
    draggable: VueDraggableNext,
  },
})
export default class ShowCaseUpdateView extends Vue {
  public idCluster: any = 0;
  public state: State = {
    items: [],
    allItems: [],
    selectedItemNames: [],
    products: [],
    serviceError: false,
    loading: true,
    text: "Carregando dados...",
    cluster: {
      name: "",
      slug: "",
      description: "",
      sales_channel_id: 26,
      is_enabled: true,
      min: 1,
      max: 1,
    },
    timeout: 1000,
  };
  public itemNames: string[] = [];

  /**
   * @description  Regras de validação do formuário
   */
  public selectionRule() {
    return (value: string[]) => {
      if (value.length < this.state.cluster.min) {
        return `Você deve selecionar pelo menos ${this.state.cluster.min} itens.`;
      }
      if (value.length > this.state.cluster.max) {
        return `Você deve selecionar no máximo ${this.state.cluster.max} itens.`;
      }
      return true;
    };
  }

  public toClean(): void {
    this.setState({
      selectedItemNames: [],
    });
  }

  public getState(): State {
    return this.state;
  }

  get isFormValid(): boolean {
    return this.state.selectedItemNames.length >= this.state.cluster.min && this.state.selectedItemNames.length <= this.state.cluster.max;
  }

  /**
   * @param newState
   * @private
   */
  private setState(newState: Partial<State>) {
    this.state = {
      ...this.state,
      ...newState,
    };
    if (newState.items !== undefined) {
      this.itemNames = newState.items.map((item) => item.name);
    }
  }

  public removeItem(item: string) {
    const index = this.state.selectedItemNames.indexOf(item);
    if (index > -1) {
      this.state.selectedItemNames.splice(index, 1);
    }
  }

  get filteredItems(): Array<Item> {
    return this.state.items.filter((item) => this.state.selectedItemNames.includes(item.name));
  }

  /**
   * @param newOrder
   */
  set filteredItems(newOrder: Array<Item>) {
    const newOrderNames = newOrder.map((item) => item.name);
    this.state.items.sort((a, b) => {
      return newOrderNames.indexOf(a.name) - newOrderNames.indexOf(b.name);
    });
  }

  public onDragEnd() {
    this.state.selectedItemNames = this.filteredItems.map((item) => item.name);
  }

  mounted(): void {
    this.idCluster = Number(this.$router.params.id);
    this.getCluster(this.idCluster);
    this.itemNames = [...this.state.selectedItemNames];
  }

  private sortListFirst(): void {
    if (this.state.selectedItemNames.length > 0) {
      this.filteredItems = this.state.selectedItemNames.map((value) => {
        return { name: value };
      });
    }
  }

  private async getAllCatalog(): Promise<void> {
    try {
      this.setState({ loading: true, text: "Carregando dados..." });
      const response = await services.CatalogService.getAllCatalog();
      this.setItems(response.data);
      this.setState({ serviceError: false, text: "", allItems: response.data });
    } catch (e: any) {
      this.setState({ serviceError: true, text: e.message, loading: false });
    } finally {
      this.sortListFirst();
      this.setState({ loading: false });
    }
  }

  /**
   * @param data
   * @private
   */
  private setItems(data: Array<any>): void {
    if (data.length) {
      const items = data.map((item: any) => {
        return { name: `Grupo ${item.groupId} - Código Fipe ${item.fipeCode}` };
      });
      this.setState({ items });
    }
  }

  private getItems(data: Array<IProduct>): Array<string> {
    if (data.length) {
      return data.map((item: IProduct) => {
        return `Grupo ${item.catalog.catalog_group} - Código Fipe ${item.catalog.fipe_code}`;
      });
    }
    return [];
  }

  async destroy(idCluster: number, idCatalog: number): Promise<void> {
    await services.ClusterService.destroyProducts(idCluster, idCatalog);
  }

  private async removePreviousItems(): Promise<void> {
    if (this.state.products) {
      for (const item of this.state.products) {
        await this.destroy(this.idCluster, item.catalog_id);
      }
    }
  }

  public async toSave(): Promise<void> {
    this.setState({ loading: true, text: "Salvando dados..." });
    try {
      await this.removePreviousItems();
      for (const item of this.state.selectedItemNames) {
        const index = this.state.selectedItemNames.indexOf(item);
        const idCatalog = this.findItemByText(item)?.id;
        if (idCatalog !== undefined) {
          const payload: Partial<IProduct> = {
            order: index + 1,
            is_enabled: true,
            catalog_id: idCatalog,
          };
          await services.ClusterService.storeClusterProducts(payload as IProduct, this.idCluster);
          await services.ClusterService.updateClusterProducts(payload as IProduct, this.idCluster, idCatalog);
        } else {
          console.error(`Item não encontrado: ${item}`);
        }
      }
    } catch (error) {
      console.error("Erro ao processar os itens:", error);
    } finally {
      this.setState({ loading: false });
      setTimeout(() => {
        this.$router.push("/clusters");
      }, this.state.timeout);
    }
  }

  /**
   * @param data
   * @private
   */
  private setCluster(data: ClusterStoreRequest): void {
    this.setState({
      cluster: {
        name: data.name,
        slug: data.slug,
        description: data.description,
        sales_channel_id: data.sales_channel_id,
        is_enabled: data.is_enabled,
        min: data.min,
        max: data.max,
        products: data.products,
      },
    });
  }

  /**
   * @param id
   */
  async getCluster(id: unknown) {
    try {
      this.setState({ loading: true, text: "Carregando dados..." });
      const response = await services.ClusterService.getCluster(id);
      this.setCluster(response.results);
      this.setState({ serviceError: false, text: "", products: response.results.products });
      this.setState({ selectedItemNames: this.getItems(response.results.products) });
      await this.getAllCatalog();
    } catch (e: any) {
      console.error("Error: ", e);
      this.setState({ serviceError: true, text: e.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  public findItemByText(text: string): ICatalog | any {
    const [_, groupAndFipeCode] = text.split("Grupo ");
    const [groupId, fipeCode] = groupAndFipeCode.split(" - Código Fipe ");
    return this.state.allItems.find((item) => item.groupId === groupId && item.fipeCode === fipeCode);
  }
}
</script>

<style scoped>
.btn-cancel {
  margin-right: 1rem;
}

.list-group {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.list-group-item {
  padding: 5px;
  margin-bottom: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.dragArea {
  cursor: move;
}
.position {
  font-weight: bold;
  font-size: x-small;
}
</style>
