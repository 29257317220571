import { BaseService } from "../BaseService";
import { LoginRequest } from "./requests/LoginRequest";
import { AppResponse } from "./responses/AppResponse";
import { UserResponse } from "./responses/UserResponse";
import { AuthShowDataUserResponse } from "@/models/auth/AuthShowDataUserResponse";
import { TokenResponse } from "./responses/SendToken/TokenResponse";
import { TokenValidateResponse } from "./responses/ValidateToken/TokenValidationResponse";

export class AuthService extends BaseService {
  private static classInstance?: AuthService;

  private user = "auth/user";
  private app = "auth/app";
  private sToken = "mfa/sendToken";
  private vToken = "mfa/validateToken";

  constructor(token: string) {
    super("", token);
  }

  public static getInstance(token: string) {
    if (!this.classInstance) {
      this.classInstance = new AuthService(token);
    }
    return this.classInstance;
  }

  public async doAuthApp(): Promise<AppResponse> {
    const response = await this.instance.post<AppResponse>(this.app, {});

    return response.data;
  }

  public async doAuthUser(form: LoginRequest): Promise<UserResponse> {
    const response = await this.instance.post<UserResponse>(this.user, form);

    return response.data;
  }

  public async getUserData(): Promise<AuthShowDataUserResponse> {
    const response = await this.instance.get<AuthShowDataUserResponse>(this.user, this.getConfigHeaders());

    return response.data;
  }

  public async sendToken(form: TokenResponse): Promise<TokenResponse> {
    const tokenApp = localStorage.getItem("TOKEN_APP");

    const requestBody = {
      ...form,
      TokenApp: tokenApp,
    };

    const response = await this.instance.post<TokenResponse>(this.sToken, requestBody);
    return response.data;
  }

  public async validateToken(tokenValidate: number): Promise<TokenValidateResponse> {
    const tokenApp = localStorage.getItem("TOKEN_APP");
    const tokenLogin = localStorage.getItem("BEARER_TOKEN");
    const metodoPadrao = localStorage.getItem("MP");

    const requestBody = {
      TokenValidate: tokenValidate,
      TokenApp: tokenApp,
      TokenLogin: tokenLogin,
      MetodoPadrao: metodoPadrao,
    };

    const response = await this.instance.post<TokenValidateResponse>(this.vToken, requestBody);
    return response.data;
  }
}
