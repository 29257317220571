import { BaseService } from "../BaseService";

class PeopleService extends BaseService {
  async getAll() {
    const route = `/api/v1/peoples`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }
}

export default new PeopleService("", "");
