<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Clusters</h1>
    <a href="/clusters/store" class="text-decoration-none">
      <v-btn class="text-white elevation-0" color="orange">
        <v-icon> mdi-plus</v-icon>
        Novo cluster
      </v-btn>
    </a>
  </div>
  <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="items" :loading="loading" item-value="name" class="elevation-1" hide-default-footer>
    <template v-slot:loading>
      <v-sheet class="d-flex align-center justify-center pt-3 pb-3">
        <v-chip> Carregando... </v-chip>
      </v-sheet>
    </template>
    <template v-slot:bottom></template>
    <template v-slot:[`item.status`]="{ item }">
      <div class="text-sm-body-2">
        <span :class="{ 'bg-red': !item.props.title.is_enabled, 'bg-green': !!item.props.title.is_enabled }" class="w-3 h-3 rounded-circle d-inline-block mr-2"></span>
        {{ !!item.props.title.is_enabled ? "Ativo" : "Inativo" }}
      </div>
    </template>
    <template v-slot:[`item.options`]="{ item }">
      <v-menu bottom left v-if="item.props.title.is_enabled">
        <template v-slot:activator="{ on, props }">
          <v-btn dark icon v-bind="props" v-on="on" style="box-shadow: none">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :href="'/clusters/update/' + item.props.title.id">
            <v-list-item-title>Editar Cluster</v-list-item-title>
          </v-list-item>
          <v-list-item :href="'/showcase/update/' + item.props.title.id">
            <v-list-item-title>Editar Vitrine</v-list-item-title>
          </v-list-item>
          <v-list-item @click="destroy(item.props.title.id)">
            <v-list-item-title>Deletar Cluster</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table-server>
  <v-container>
    <v-dialog v-model="confirmDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmação</v-card-title>
        <v-card-text>Você tem certeza que deseja deletar este item?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDialog = false">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDelete">Deletar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { ICluster } from "@/models/cluster/Cluster";
import { IClusterResponse } from "@/models/cluster/ClusterResponse";

@Options({
  components: {},
})
export default class ClusterIndexView extends Vue {
  public items: Array<ICluster> = [];
  public itemsPerPage = 0;
  public confirmDialog = false;
  private deleteId: number | null = null;
  public snackbar = false;
  public snackbarText = "";
  public snackbarColor = "";
  public headers = [
    {
      title: "Nome",
      align: "start",
      sortable: false,
      key: "name",
    },
    { title: "Descrição", align: "end", key: "description", sortable: false },
    { title: "Canal Id", align: "end", key: "sales_channel_id", sortable: false },
    { title: "Status", align: "end", key: "status", sortable: false },
    { title: "Mínimo", align: "end", key: "min", sortable: false },
    { title: "Máximo", align: "end", key: "max", sortable: false },
    { title: "Ações", align: "end", key: "options", sortable: false },
  ];
  public sortSelected = "name";
  public loading = true;

  public showConfirmDialog(id: number) {
    this.deleteId = id;
    this.confirmDialog = true;
  }

  public async confirmDelete(): Promise<void> {
    if (this.deleteId !== null) {
      this.loading = true;
      try {
        const response = await services.ClusterService.destroy(this.deleteId);
        this.snackbarText = response.message;
        this.snackbarColor = "success";
        this.snackbar = true;
      } catch (error: any) {
        console.error("Erro ao deletar item:", error.message);
        this.snackbarText = error.message;
        this.snackbarColor = "danger";
        this.snackbar = true;
      } finally {
        this.confirmDialog = false;
        this.deleteId = null;
        this.loading = false;
      }
    }
  }

  mounted(): void {
    this.willMountDataPage();
  }

  async willMountDataPage(): Promise<void> {
    const response = await services.ClusterService.getAllClusters();
    this.unMountRequest(response);
    this.changeLoading();
  }

  private changeLoading(): void {
    this.loading = !this.loading;
  }

  private unMountRequest(response: IClusterResponse) {
    if (response.results) {
      this.items = response.results as Array<ICluster>;
    }
  }

  async destroy(id: number): Promise<void> {
    this.showConfirmDialog(id);
  }
}
</script>
<style lang="css"></style>
