<template>
  <div class="home">
    <h1 style="color: red">Lista de tarefas</h1>
    <br />
    <input type="text" v-model="newTask" />
    <br />
    <br />
    <input type="button" v-if="newTask?.length > 0" @click="add" value="Salvar" />
    <input type="button" v-if="$store.getters.tasks.length > 0" @click="clean" value="Apagar tudo" />
    <input type="button" @click="updateWithServer" value="pegar do servidor" />
    <br />
    <br />
    <br />
    <br />
    <div class="tasks">
      <TaskComponent v-for="(task, i) in $store.getters.tasks" :key="i" :task="task" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TaskComponent from "../components/TaskComponent.vue";
import { Task } from "@/models";
import { UserProfileService } from "@/services/profile/UserProfileService";

@Options({
  components: {
    TaskComponent,
  },
})
export default class HomeView extends Vue {
  public newTask = "";
  mounted() {
    console.log(this.$store.state.count); // this.$store
  }
  clean(): void {
    this.$store.commit("CLEAN", null);
  }
  async updateWithServer() {
    const todo = await UserProfileService.getInstance("").allTodo();

    this.$store.commit(
      "SYNC",
      todo.map((t) => {
        return { content: t.title, done: t.completed };
      })
    );
  }
  add(): void {
    if (this.newTask) {
      const tt: Task = { content: this.newTask, done: false } as Task;

      console.log(tt);
      this.$store.commit("ADD_TASK", tt);
      console.log(this.$store.getters.tasks);
      this.newTask = "";
    }
  }
}
</script>
