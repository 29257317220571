import { RouteIndexWithPaginateResponse } from "@/models/route/RouteIndexWithPaginateResponse";
import { RouteStoreRequest } from "@/models/route/RouteStoreRequest";
import { BaseService } from "../BaseService";
class RouteService extends BaseService {
  async getAllRoutes(page = 1, needle: string | null = null, sortColumn: string | null = "name") {
    let route = `/api/v1/routes?page=${page}&order=ASC&column=${sortColumn}`;
    if (needle) {
      route = route + `&search=${needle}`;
    }
    const response = await this.instance.get<RouteIndexWithPaginateResponse>(route, this.getConfigHeaders());
    return response.data;
  }
  async getAllRoutesWithoutPaginate(isWithoutPaginate = false) {
    const currentRoute = `/api/v1/routes?without_paginate=${isWithoutPaginate}`;
    const response = await this.get(currentRoute);
    return response.data;
  }
  async storeNewRoute(dataRequest: RouteStoreRequest) {
    const route = `/api/v1/routes`;
    const response = await this.post(route, dataRequest);

    return response.data;
  }
  async getRoute(routeIdentification: number) {
    const route = `/api/v1/routes/${routeIdentification}`;
    const response = await this.get(route);
    return response.data;
  }
  async updateRoute(dataRequest: RouteStoreRequest, routeIdentification: number) {
    const route = `/api/v1/routes/${routeIdentification}`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }
}
export default new RouteService("", "");
