<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Atualizar Cluster</h1>
    <a class="text-decoration-none">
      <v-btn class="btn-action text-white elevation-0" color="grey" href="/clusters">
        <v-icon> mdi-cancel </v-icon>
        Cancelar
      </v-btn>
      <v-btn class="text-white elevation-0" color="orange" @click="update" v-bind:disabled="btn_disabled">
        <v-icon> mdi-plus </v-icon>
        Salvar
      </v-btn>
    </a>
  </div>
  <v-container>
    <template v-if="loading">
      <v-sheet class="d-flex align-center justify-center pt-3 pb-3">
        <v-chip :color="serviceErro ? 'red' : ''"> {{ text }} </v-chip>
      </v-sheet>
    </template>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field v-model="clusterStoreRequest.name" label="Nome" :rules="rules" hide-details="auto" :disabled="loading"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="clusterStoreRequest.slug" label="Slug" :rules="rules" hide-details="auto" :disabled="loading"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="clusterStoreRequest.description" label="Descrição" :rules="rules" hide-details="auto" :disabled="loading"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select v-model="clusterStoreRequest.sales_channel_id" :readonly="true" :disabled="false" :items="channels" label="Canais de Venda" hint="Esolha o canal de venda" persistent-hint item-title="name" item-value="id"></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="clusterStoreRequest.min" label="Min" hide-details="auto" :disabled="loading"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="clusterStoreRequest.max" label="Max" hide-details="auto" :disabled="loading"></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-switch v-model="clusterStoreRequest.is_enabled" color="orange" :label="clusterStoreRequest.is_enabled ? 'Ativado' : 'Desativado'" item-title="Ativo?" item-value="is_enabled" :disabled="loading"></v-switch>
      </v-col>
    </v-row>
  </v-container>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import messages from "@/constants/messages";
import { ClusterStoreRequest } from "@/models/cluster/ClusterStoreRequest";
import { getFromStorage } from "@/utils/localStorage";

@Options({
  components: {},
})
export default class ClusterCreateView extends Vue {
  public rules = [(value: any) => !!value || "Required.", (value: string) => (value && value.length >= 3) || "Min 3 characters"];
  public serviceErro = false;
  public messages = {};
  public loading = true;
  public id: any = 0;
  public snackbar = false;
  public text = "Carregando...";
  public timeout = 6000;
  public btn_disabled = false;
  public channels = [];
  $route: any;
  public module_channels: any;

  public clusterStoreRequest: ClusterStoreRequest = {
    name: "",
    slug: "",
    description: "",
    sales_channel_id: 26,
    is_enabled: true,
    min: 1,
    max: 1,
  };

  /**
   * @param data
   * @private
   */
  private setClusterStoreRequest(data: ClusterStoreRequest): void {
    this.clusterStoreRequest.name = data.name;
    this.clusterStoreRequest.slug = data.slug;
    this.clusterStoreRequest.description = data.description;
    this.clusterStoreRequest.sales_channel_id = +this.module_channels;
    this.clusterStoreRequest.is_enabled = data.is_enabled;
    this.clusterStoreRequest.min = data.min;
    this.clusterStoreRequest.max = data.max;
  }

  mounted(): void {
    this.id = Number(this.$route.params.id);
    this.getCluster(this.id);
    this.getChannels();
    this.messages = messages;
    this.module_channels = getFromStorage("MODULE_CHANNEL");
  }

  async getChannels() {
    const response = await services.ChannelService.getAllChannelsWithoutPaginate();
    this.channels = response.results;
  }

  private changeLoading(): void {
    this.loading = !this.loading;
  }

  public closeSnack(): void {
    this.snackbar = false;
    this.$router.push("/clusters");
  }

  /**
   * @param e
   */
  public async update(e: any): Promise<void> {
    this.loading = true;
    this.text = "Salvando dados...";
    const response = await services.ClusterService.updateCluster(this.clusterStoreRequest as ClusterStoreRequest, this.id);
    this.text = response.message;
    this.snackbar = true;
    this.changeLoading();
    setTimeout(() => {
      this.$router.push("/clusters");
    }, this.timeout);
  }

  /**
   * @param id
   */
  async getCluster(id: number) {
    try {
      const response = await services.ClusterService.getCluster(id);
      this.setClusterStoreRequest(response.results);
      this.changeLoading();
    } catch (e: any) {
      console.log("Error: ", e);
      this.serviceErro = true;
      this.text = e.message;
    }
  }
}
</script>
<style lang="css"></style>
