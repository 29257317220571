<template>
  <div class="app">
    <h1>Perfil</h1>
    <div class="row">
      <div class="child">
        <ul>
          <li v-for="(t, i) in todo" :key="i"> {{ i }} - {{ t.title }}</li>
        </ul>
      </div>
      <div class="child">
        <ul>
          <li v-for="(u, i) in users" :key="i">{{ u.email }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as models from "@/models/index";
import { Options, Vue } from "vue-class-component";
import { UserProfileService } from "@/services/profile/UserProfileService";

@Options({
  components: {},
})
export default class ProfileView extends Vue {
  public todo: models.Todo[] = [];
  public users: models.User[] = [];

  async mounted(): Promise<void> {
    this.apiCall();
  }

  async apiCall(): Promise<void> {
    this.todo = await UserProfileService.getInstance("").allTodo();
    this.users = await UserProfileService.getInstance("").allUsers();
  }
}
</script>
<style>
.row {
  padding: 10px;
  display: inline-block;
}

.child {
  width: 40%;
  float: left;
  padding: 20px;
  margin: 10px;
  border: 2px solid red;
}
</style>
