<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Chaves de Acesso</h1>
    <a href="/access-key/store" class="text-decoration-none">
      <v-btn class="text-white" color="orange">
        <v-icon> mdi-plus </v-icon>
        Novo Chave
      </v-btn>
    </a>
  </div>
  <v-row class="mb-3 flex justify-between">
    <v-col sm="3" md="4">
      <v-text-field label="Buscar..." prepend-inner-icon="mdi-magnify" clearable single-line density="compact" variant="solo" hide-details v-model="needleSearch" @input="searchByNeedle" @click:clear="clearFilter"></v-text-field>
    </v-col>
    <v-col sm="3" md="2">
      <div class="d-flex justify-space-between">
        <div class="d-flex align-center gap-4 mr-6">
          <v-menu transition="slide-y-transition">
            <template v-slot:activator="{ props }">
              <v-btn color="white" v-bind="props">
                Ordenar por:
                <v-icon left dark class="ml-1"> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, i) in headersOrdenar" :key="i" :value="i">
                <v-list-item-title @click="changeSortSelected(item.key)">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-col>
  </v-row>
  <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="items" :loading="isLoadingTable" item-value="name" class="elevation-1" hide-default-footer>
    <template v-slot:bottom> </template>
    <template v-slot:[`item.is_enabled`]="{ item }">
      <div class="text-sm-body-2"> <span :class="{ 'bg-red': !!item.props.title.is_enabled, 'bg-green': !!item.props.title.is_enabled }" class="w-3 h-3 rounded-circle d-inline-block mr-2"></span>{{ !!item.props.title.is_enabled ? "Ativo" : "Inativo" }}</div>
    </template>
    <template v-slot:[`item.token_validity`]="{ item }">
      <div class="text-sm-body-2"> <span :class="{ 'bg-red': !!item.props.title.token_validity, 'bg-green': !!item.props.title.token_validity }" class="w-3 h-3 rounded-circle d-inline-block mr-2"></span>{{ !!item.props.title.token_validity ? "Ativo" : "Expirado" }}</div>
    </template>
    <template v-slot:[`item.options`]="{ item }">
      <v-menu bottom left>
        <template v-slot:activator="{ on, props }">
          <v-btn dark icon v-bind="props" v-on="on" style="box-shadow: none">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :href="'/access-key/update/' + item.props.title.id">
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="generateToken(item)">Gerar Token</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table-server>
  <v-row class="mt-2 d-flex align-center" justify="center">
    <div class="text-center">
      <v-pagination v-model="currentPage" :length="numberOfPages" :total-visible="7" @next="nextPage" @prev="prevPage" @update:modelValue="loadPage" color="orange"></v-pagination>
      <div> {{ pageFrom }} a {{ pageTo }} de {{ totalOfRows }} resultados </div>
    </div>
  </v-row>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { AccessKey } from "@/models/access-key/AccessKey";

@Options({
  components: {},
})
export default class AccessKeyIndexView extends Vue {
  public items: Array<AccessKey> = [];
  public currentPage = 1;
  public numberOfPages = 0;
  public itemsPerPage = 0;
  public totalOfRows = 0;
  public pageFrom = 0;
  public pageTo = 1;
  public headers = [
    {
      title: "Nome",
      align: "start",
      sortable: false,
      key: "name",
    },
    {
      title: "Perfil",
      align: "end",
      sortable: false,
      key: "profile",
    },
    { title: "Status", align: "end", key: "is_enabled", sortable: false },
    { title: "Token de Acesso", align: "end", key: "token_validity", sortable: false },
    { title: "Ações", align: "end", key: "options", sortable: false },
  ];
  public headersOrdenar = [
    {
      title: "Nome",
      align: "start",
      sortable: false,
      key: "name",
    },
    { title: "Status", align: "end", key: "is_enabled", sortable: false },
  ];
  public sortSelected = "name";
  public isLoadingTable = true;
  public needleSearch = null;
  mounted(): void {
    this.willMountDataPage();
  }
  async willMountDataPage(): Promise<void> {
    const { currentPage } = this;
    const response = await services.UserService.typeApplication(currentPage);
    this.unMountRequest(response);

    this.changeLoading();
  }
  nextPage(): void {
    this.changeLoading();
    this.getAppList(this.currentPage, this.needleSearch, this.sortSelected);
  }
  prevPage(): void {
    this.changeLoading();
    this.getAppList(this.currentPage, this.needleSearch, this.sortSelected);
  }
  loadPage(value: number): void {
    this.getAppList(value, this.needleSearch, this.sortSelected);
    this.changeLoading();
  }
  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }
  async getAppList(page: number, needle: string | null = null, sortColumn: string | null = null) {
    try {
      if (this.isNullableSort(sortColumn)) {
        this.resetSortSelected();
        sortColumn = this.sortSelected;
      }
      const response = await services.UserService.typeApplication(page, needle, sortColumn);
      this.unMountRequest(response);
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
  }
  private isNullableSort(sort: string | null) {
    return sort === null;
  }
  private resetSortSelected() {
    this.loadSortSelected();
  }
  private loadSortSelected() {
    this.sortSelected = this.headers[0].key;
  }
  private unMountRequest(response: any) {
    if (response.results) {
      this.currentPage = response.results.meta.pagination.current_page;
      this.items = response.results.data as Array<AccessKey>;
      this.numberOfPages = response.results.meta.pagination.total_pages;
      this.itemsPerPage = response.results.meta.pagination.per_page;
      this.totalOfRows = response.results.meta.pagination.total;
      this.pageFrom = response.results.meta.pagination.current_page;
      this.pageTo = response.results.meta.pagination.total_pages;
    }
  }
  searchByNeedle() {
    if (this.needleSearch != null && Array.from(this.needleSearch).length > 3) {
      this.changeLoading();
      this.items = [];
      this.getAppList(1, this.needleSearch);
    }
  }
  private clearFilter() {
    this.changeLoading();
    this.items = [];
    this.currentPage = 1;
    this.getAppList(1);
  }
  changeSortSelected(itemSelected: string) {
    this.sortSelected = itemSelected;
    this.changeLoading();
    this.getAppList(this.currentPage, null, itemSelected);
  }
  async generateToken(accessKey: any) {
    try {
      let data_send = {
        username: accessKey.props.title.username,
        pass_application: accessKey.props.title.pass_application,
      };
      const response = await services.UserService.generateToken(data_send);
    } catch (e) {
      console.log("ERRO", e);
    }
    this.changeLoading();
    this.getAppList(this.currentPage, null);
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}
</style>
