import { BaseService } from "../../BaseService";
import { CatalogPricingRequestDTO } from "@/models/catalog/catalog-pricing/CatalogPricingStoreRequest";

class CatalogPricingService extends BaseService {
  async store(dataRequest: CatalogPricingRequestDTO) {
    const route = `/api/v1/catalog/pricing`;
    const response = await this.post(route, dataRequest);
    return response.data;
  }

  async getCatalogPricing(group: string, fipecode: string) {
    const route = `/api/v1/catalog/pricing?group=${group}&fipecode=${fipecode}`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }
}

export default new CatalogPricingService("", "");
