import "@babel/polyfill";
import "mutationobserver-shim";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "@mdi/font/css/materialdesignicons.css";
import "@/assets/scss/global.scss";
import "vuetify/styles";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { createVuetify } from "vuetify";
import { VDataTable, VDataTableServer } from "vuetify/labs/VDataTable";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { Money3Directive } from "v-money3";

const vuetify = createVuetify({
  components: {
    ...components,
    VDataTable,
    VDataTableServer,
  },
  directives,
  defaults: {
    VDataTable: {
      fixedHeader: true,
      noDataText: "Resultados não encontrados",
    },
  },
});

// Prod
const siteKey = "6Lcp0LQaAAAAAKhJ_c6vSt-aJwHQqho8SMMPW0hx";

// Local-Dev
// const siteKey = "6LejBpQaAAAAACcyPnhP0D8ddhN8xGEAsIJCB5v_";

const app = createApp(App)
  .use(store)
  .use(vuetify)
  .use(VueReCaptcha, {
    siteKey: siteKey,
    loaderOptions: {
      useRecaptchaNet: true,
    },
  })
  .directive("money3", Money3Directive);

Sentry.init({
  app,
  dsn: "https://75222572f8234dd58934ca32f7c4c196@o373455.ingest.sentry.io/4504758945120256",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["gestao-produtos.movida.com.br", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.use(router);
app.mount("#app");
