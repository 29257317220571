import { BaseService } from "@/services/BaseService";
import * as models from "@/models/index";
import { TodoRequest } from "./TodoRequest";
import { UserRequest } from "./UserRequest";

export class UserProfileService extends BaseService {
  private static classInstance?: UserProfileService;

  constructor(token: string) {
    super("https://jsonplaceholder.typicode.com/", token);
  }

  public static getInstance(token: string) {
    if (!this.classInstance) {
      this.classInstance = new UserProfileService(token);
    }

    return this.classInstance;
  }

  //mapeamento direto para array
  public async allTodo(): Promise<models.Todo[]> {
    const response = await this.instance.get<models.Todo[]>("/todos", {});

    return response.data;
  }

  //mapeamento direto para envelope
  public async allUsers(): Promise<models.User[]> {
    const url = "https://reqres.in/api/users";
    const response = await this.instance.get<UserRequest>(url, {});

    return response.data.data;
  }

  //trash
  public allTodoNoAwait = () =>
    this.instance.get("/todos").then((response) => {
      if (response) {
        return response.data;
      }
    });
}
