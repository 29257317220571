import { BaseService } from "../BaseService";
import { ProfileIndexWithPaginateResponse } from "@/models/profile/ProfileIndexWithPaginateResponse";
import { ProfileStoreRequest } from "@/models/profile/ProfileStoreRequest";

class ProfileService extends BaseService {
  /**
   * index
   * @param page
   * @param needle
   * @param sortColumn
   */
  async index(page = 1, needle: string | null = null, sortColumn: string | null = "name") {
    let route = `/api/v1/profiles?page=${page}&order=ASC&column=${sortColumn}`;
    if (needle) {
      route = route + `&search=${needle}`;
    }
    const response = await this.instance.get<ProfileIndexWithPaginateResponse>(route, this.getConfigHeaders());
    return response.data;
  }
  /**
   * view
   * @param Identification
   */
  async view(Identification: number) {
    const route = `/api/v1/profiles/${Identification}`;
    const response = await this.get(route);
    return response.data;
  }
  /**
   * store
   * @param dataRequest
   */
  async store(dataRequest: ProfileStoreRequest) {
    const route = `/api/v1/profiles`;
    const response = await this.post(route, dataRequest);
    return response.data;
  }

  /**
   * update
   * @param dataRequest
   * @param Identification
   */
  async update(dataRequest: ProfileStoreRequest, Identification: number) {
    const route = `/api/v1/profiles/${Identification}`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }

  /**
   * all
   */
  async all() {
    const route = `/api/v1/profiles?without_paginate=true`;
    const response = await this.get(route);
    return response.data;
  }

  /**
   * getPermissions
   * @param Identification
   */
  async getPermissions(Identification: number) {
    const route = `/api/v1/profiles/${Identification}/permissions`;
    const response = await this.get(route);
    return response.data;
  }

  async updatePermission(dataRequest: any, Identification: number) {
    const route = `/api/v1/profiles/${Identification}/permissions`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }
}
export default new ProfileService("", "");
