import { BaseService } from "../../BaseService";

class SubchannelsService extends BaseService {
  async getAllSubchannelsWithoutPaginate() {
    const route = `/api/v1/subchannels?without_paginate=true`;
    const response = await this.get(route);
    return response.data;
  }

  async updateAndStoreSubchannels(catalogId: number, subchannels: any) {
    const route = `api/v1/catalog/${catalogId}/subchannels`;
    const response = await this.put(route, subchannels);
    return response.data;
  }

  async getSubchannelsSelected(catalogId: number) {
    const route = `api/v1/catalog/${catalogId}/subchannels`;
    const response = await this.get(route);
    return response.data;
  }
}

export default new SubchannelsService("", "");
