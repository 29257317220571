import { BaseService } from "../../BaseService";
import { CatalogIndexWithPaginateResponse } from "@/models/catalog/CatalogIndexWithPaginateResponse";
import { CatalogGroupTagStoreRequest } from "@/models/catalogTag/CatalogGroupTagStoreRequest";

class CatalogGroupTagService extends BaseService {
  async store(dataRequest: CatalogGroupTagStoreRequest) {
    const route = `/api/v1/catalogGroupTag`;
    const catalog_tag_ids = dataRequest.catalog_tag_ids.map((tag_id: number) => {
      return { tag_id: tag_id };
    });
    const requestData = { ...dataRequest, catalog_tag_ids: catalog_tag_ids };
    const response = await this.post(route, requestData);
    return response.data;
  }

  async getCatalogGroupTags() {
    const route = `/api/v1/catalogGroupTags`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async getGroupTagsByGroupVehicle(groupVehicle: string) {
    const route = `/api/v1/catalogGroupTag/listGroupTagsByGroupVehicle/${groupVehicle}`;
    const response = await this.instance.get(route, this.getConfigHeaders());
    return response.data;
  }

  async deleteGroupsTagsByCatalogIdBeforeStore(catalogId: any) {
    const route = `/api/v1/catalogGroupTag/deleteGroupTagsByCatalogId/${catalogId}`;
    const response = await this.instance.delete(route, this.getConfigHeaders());
    return response.data;
  }

  async enableAndDisableGroupTags(dataRequest: CatalogGroupTagStoreRequest) {
    const route = `/api/v1/catalogGroupTag/enableAndDisableGroupTags`;
    const response = await this.put(route, dataRequest);
    return response.data;
  }
}
export default new CatalogGroupTagService("", "");
