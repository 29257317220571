<template>
  <div class="home">
    <header>
      <h1 class="pt-6 mb-4 neutral-900">Início</h1>
      <p class="neutral-500">Bem-vindo ao ambiente {{ moduleName }} do Gestão de Produtos</p>
    </header>
    <v-row>
      <v-col cols="12" md="12" class="mt-n1">
        <v-list density="default" class="navbar-home">
          <v-list-item v-for="(item, i) in items" :key="i" :value="item" :href="item.href">
            <template v-slot:prepend>
              <v-icon :icon="item.icon" size="large"></v-icon>
            </template>
            <v-list-item-title v-text="item.text"></v-list-item-title>
            <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
            <template v-slot:append>
              <v-icon icon="mdi-arrow-right" size="large" class="arrow"></v-icon>
            </template>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Menu } from "@/models/menu/Menu";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class HomeView extends Vue {
  get items(): Array<Menu> {
    return this.$store.getters["menu"];
  }
  get moduleName(): string {
    return this.$store.getters["moduleName"];
  }
}
</script>
<style lang="scss" scoped>
.navbar-home .v-list-item--variant-text {
  background: #f6f8f9;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 12px;
  border: 2px solid #dde6ec;
  padding: 28px 24px !important;
}

.v-list-item:hover .v-list-item-title,
.v-list-item:hover .v-list-item__append > .v-icon {
  color: var(--movida-orange);
  opacity: 1 !important;
}

.v-list-item:hover .v-list-item-title {
  text-decoration: underline;
}

.v-list-item-title {
  font-weight: bold;
  color: var(--neutral-700);
}

.arrow {
  opacity: 0 !important;
}
</style>
