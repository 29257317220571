<template>
  <div class="logo">
    <img alt="Logo Movida" src="@/assets/brand-light.svg" />
  </div>
  <div class="d-flex justify-center align-center min-h-100vh">
    <v-flex class="justify-center">
      <v-col>
        <v-card elevation="5" class="pa-md-16 pa-sm-4 px-md-16 px-sm-4 rounded-lg">
          <v-alert v-if="state.showErrorMessage" closable density="compact" type="error" text="Erro ao tentar fazer login!"></v-alert>
          <v-row no-gutters>
            <v-container>
              <header>
                <h1 class="neutral-900">Acesse o Gestão</h1>
                <p class="neutral-300">Para continuar, digite seu usuário e senha Movida.</p>
              </header>
            </v-container>
          </v-row>
          <v-form v-model="valid" name="form-login" @submit.prevent="onSubmit()">
            <v-container>
              <v-row>
                <v-col cols="12" md="12" class="py-4">
                  <v-text-field :color="'movida-orange'" :focused="true" :disabled="state.loading" v-model.trim="form.username" type="text" label="Usuário" variant="outlined" outlined dense :rules="[required]"></v-text-field>
                </v-col>
                <v-col cols="12" md="12" class="py-0">
                  <v-text-field
                    v-model.trim="form.password"
                    variant="outlined"
                    :color="'movida-orange'"
                    label="Senha"
                    :append-inner-icon="state?.isVisiblePass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    @click:append-inner="() => (state = { isVisiblePass: !state?.isVisiblePass })"
                    :type="state?.isVisiblePass ? 'text' : 'password'"
                    outlined
                    dense
                    :rules="[required]"
                    :disabled="state.loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" class="py-4 justify-center">
                  <v-btn :disabled="state.loading" :loading="state.loading" type="submit" color="movida-orange" size="large" block> Entrar </v-btn>
                </v-col>
                <v-col cols="12" md="12" class="py-0 text-center">
                  <a class="neutral-500 text-decoration-none font-weight-bold" title="Esqueci minha senha" href="/forgot-password"> Esqueci minha senha </a>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-flex>
  </div>
</template>

<script lang="ts">
import { LoginRequest } from "@/services/auth/requests/LoginRequest";
import { Options, Vue } from "vue-class-component";
import { saveOnStorage } from "@/utils/localStorage";
import * as services from "@/services";

@Options({
  components: {},
})
export default class LoginView extends Vue {
  public valid = false;
  public form: LoginRequest = {
    access_token: "",
    username: "",
    password: "",
    recaptcha: "",
  };
  private _state: unknown = {
    showErrorMessage: false,
    isVisiblePass: false,
    loading: false,
  };
  get state(): unknown {
    return this._state;
  }
  set state(value: unknown) {
    this._state = Object.assign({}, this._state, value);
  }
  mounted(): void {
    this.willMountDataPage();
  }
  required(value: string) {
    return !!value || "Campo obrigatório";
  }
  async loadRecaptcha(): Promise<void> {
    await this.$recaptchaLoaded();
    const recaptchaToken = await this.$recaptcha("login");
    this.$store.commit("SET_RECAPTCHA_TOKEN", recaptchaToken);
    this.form.recaptcha = recaptchaToken;
  }
  async willMountDataPage(): Promise<void> {
    await this.loadRecaptcha();
  }
  async onSubmit(): Promise<void> {
    if (!this.valid) return;

    this.state = { showErrorMessage: false, loading: true };
    const response = await services.AuthService.getInstance("")
      .doAuthApp()
      .catch(() => {
        this.state = { showErrorMessage: true, loading: false };
      });

    let tokenApp = response?.access_token;

    saveOnStorage("TOKEN_APP", String(tokenApp));
    this.$store.commit("SET_BEARER_TOKEN", response?.access_token);
    const loginRequest: LoginRequest = {
      recaptcha: this.form.recaptcha,
      username: this.form.username,
      password: this.form.password,
      access_token: this.$store.getters.tokens.application,
    } as LoginRequest;

    const userResponse = await services.AuthService.getInstance("").doAuthUser(loginRequest);

    if (userResponse.status) {
      saveOnStorage("BEARER_TOKEN", userResponse.data.token);
      saveOnStorage("MP", userResponse.data.mfa_padrao.MfaMetodoID);
      // saveOnStorage("EXPIRES_IN", String(userResponse.data.expires_in));
      const userData = await services.AuthService.getInstance(userResponse.data.token).getUserData();
      const TokenResponse = await services.AuthService.getInstance("").sendToken(userResponse);

      if (userData.has_success) {
        this.$store.commit("AUTH", true);
        saveOnStorage("MODULES", JSON.stringify(userData.results.modules));
        saveOnStorage("DATA_USER", JSON.stringify(userData.results));
      }
      this.$router.push("MFA");
    } else {
      await this.loadRecaptcha();
      this.state = { showErrorMessage: true, loading: false };
    }
  }
}
</script>
