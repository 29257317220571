import { BaseService } from "../BaseService";
import { IClusterResponse } from "@/models/cluster/ClusterResponse";
import { ClusterStoreRequest } from "@/models/cluster/ClusterStoreRequest";
import { IProduct } from "@/models/cluster/Cluster";

class ClusterService extends BaseService {
  async getAllClusters(): Promise<IClusterResponse> {
    const route = `/api/v1/catalog/clusters`;
    const response = await this.instance.get<IClusterResponse>(route, this.getConfigHeaders());
    return response.data;
  }

  async storeNewCluster(data: ClusterStoreRequest): Promise<IClusterResponse> {
    const route = `/api/v1/catalog/clusters`;
    const response = await this.post(route, data);
    return response.data;
  }

  async getCluster(id: number | unknown) {
    const route = `/api/v1/catalog/clusters/${id}`;
    const response = await this.get(route);
    return response.data;
  }

  async updateCluster(data: ClusterStoreRequest, id: number) {
    const route = `/api/v1/catalog/clusters/${id}`;
    const response = await this.put(route, data);
    return response.data;
  }

  async updateClusterProducts(data: IProduct, idCluster: number, idCatalog: number) {
    const route = `api/v1/catalog/clusters/${idCluster}/products/${idCatalog}`;
    const response = await this.put(route, data);
    return response.data;
  }

  async storeClusterProducts(data: IProduct, idCluster: number) {
    const route = `/api/v1/catalog/clusters/${idCluster}/products`;
    const response = await this.post(route, data);
    return response.data;
  }

  async destroy(id: number) {
    const route = `api/v1/catalog/clusters/${id}`;
    const response = await this.delete(route);
    return response.data;
  }

  async destroyProducts(idCluster: number, idCatalog: number) {
    const route = `api/v1/catalog/clusters/${idCluster}/products/${idCatalog}`;
    const response = await this.delete(route);
    return response.data;
  }
}

export default new ClusterService("", "");
