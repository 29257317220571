import { BaseService } from "../BaseService";

class ChannelService extends BaseService {
  async getAllChannelsWithoutPaginate() {
    const route = `/api/v1/channels?without_paginate=true`;
    const response = await this.get(route);
    return response.data;
  }
}
export default new ChannelService("", "");
