import { BaseService } from "../../BaseService";
import { BeZkmService } from "../../BeZkmService";

const beZkmServiceInstance = new BeZkmService("");
class CatalogCacheService extends BaseService {
  async purgeTagCache(grupo: any) {
    const route = `api/v1/showCases/cache/purge/tag`;
    const response = await this.post(route, grupo);
    return response.data;
  }

  async cacheListByTag(grupo: any) {
    const route = `api/cache-list-by-tag`;
    const response = await beZkmServiceInstance.post(route, grupo);
    return response.data;
  }
}

export default new CatalogCacheService("", "");
