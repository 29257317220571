<template>
  <div class="logo">
    <img alt="Logo Movida" src="@/assets/brand-light.svg" />
  </div>
  <div class="d-flex justify-center align-center min-h-100vh">
    <v-flex class="justify-center">
      <v-col>
        <v-card elevation="5" class="pa-md-16 pa-sm-4 px-md-16 px-sm-4 rounded-lg">
          <v-row no-gutters>
            <v-container>
              <v-icon @click="backPage" class="mb-5"> mdi-arrow-left</v-icon>
              <h1 class="text-left text-neutral-900">Selecionar ambiente</h1>
              <p class="text-neutral-600">
                Você possui acesso aos ambientes abaixo.<br />
                Selecione em qual você deseja continuar a operação:</p
              >
              <div class="mt-2 d-flex flex-column w-100">
                <v-select :color="'movida-orange'" :items="modules" item-title="name" item-value="id" placeholder="Selecione um item" dense variant="outlined" hide-details @update:modelValue="changeModuleSelected" />
                <v-checkbox label="Lembrar ambiente como padrão" />
                <div class="border border-slate-300/900 my-5"></div>
                <v-btn color="movida-orange" size="large" block @click="goToHome">
                  Continuar
                  <v-icon class="ml-1"> mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </v-container>
          </v-row>
        </v-card>
      </v-col>
    </v-flex>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { ModuleWithFunctionalities } from "@/models/auth/ModuleWithFunctionalities";
import * as services from "@/services";
import { ModuleResponse } from "@/services/auth/responses/ModuleResponse";
import { getFromStorage, saveOnStorage } from "@/utils/localStorage";
import Modules from "@/utils/Modules";

@Options({
  components: {},
})
export default class SelectModule extends Vue {
  public moduleSelectedId = 0;
  public modules: Array<ModuleWithFunctionalities> = [];
  public modulesClass = new Modules();

  mounted(): void {
    const recoveryModules = this.recoveryModulesFromStorage();
    if (recoveryModules.length > 0) {
      this.modules = recoveryModules;
    }
  }

  backPage() {
    this.$router.back();
  }

  async goToHome() {
    const { moduleSelectedId } = this;

    if (!this.isValidModule(moduleSelectedId)) {
      alert("Erro!");
      return;
    }
    saveOnStorage("KEY_MODULE_SELECTED", `${moduleSelectedId}`);

    await this.modulesClass.getNameModuleService(moduleSelectedId);

    this.$router.push("home");
  }

  changeModuleSelected(value: string) {
    this.moduleSelectedId = parseInt(value);
  }

  isValidModule(moduleIdIdentification: number) {
    return !!moduleIdIdentification;
  }

  recoveryModulesFromStorage(): Array<object> {
    let moduleList = [];
    const possibleStringModules = getFromStorage("MODULES");
    if (possibleStringModules) {
      moduleList = JSON.parse(possibleStringModules);
    }
    return moduleList;
  }
}
</script>
