export default class IconsUtil {
  public static getIcons(): string[] {
    const icons: string[] = [
      "mdi-calendar-month-outline",
      "mdi-pencil-outline",
      "mdi-car-outline",
      "mdi-image-album",
      "mdi-video-account",
      "mdi-home-outline",
      "mdi-account-credit-card",
      "mdi-account-settings-outline",
      "mdi-airplane-marker",
      "mdi-alarm-light-off",
      "mdi-alert-remove",
      "mdi-all-inclusive-box-outline",
      "mdi-alpha-i-box-outline",
      "mdi-alpha-r-box-outline",
      "mdi-arm-flex",
      "mdi-apple",
      "mdi-apple-keyboard-command",
      "mdi-application-braces-outline",
      "mdi-account-group-outline",
      "mdi-file-account-outline",
      "mdi-list-box-outline",
      "mdi-view-module-outline",
    ];
    return icons.sort();
  }
}
