<template>
  <div class="d-flex justify-space-between">
    <h1 class="">Criar Rota</h1>
    <a class="text-decoration-none">
      <v-btn class="text-white" color="grey" href="/routes">
        <v-icon> mdi-plus </v-icon>
        Cancelar
      </v-btn>
      <v-btn class="text-white" color="orange" @click="store" v-bind:disabled="btn_disabled">
        <v-icon> mdi-plus </v-icon>
        Salvar
      </v-btn>
    </a>
  </div>
  <div>
    <v-col cols="12" md="6">
      <v-text-field v-model="name" label="Nome" hide-details="auto"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field v-model="description" label="Descrição" hide-details="auto"></v-text-field>
    </v-col>
    <v-col cols="12" md="6">
      <v-select v-model="method" label="Método" :items="methodos" required item-title="name" item-value="id" persistent-hint @input="setSelected($event)"></v-select>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field v-model="path" label="Path" hide-details="auto"></v-text-field>
    </v-col>
  </div>

  <v-snackbar v-model="snackbar" :timeout="timeout">
    <v-icon class="ml-1"> mdi-information-outline </v-icon> {{ text }}
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnack"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import * as services from "@/services";
import { Module } from "@/models/module/Module";
import { ModuleIndexWithPaginateResponse } from "@/models/module/ModuleIndexWithPaginateResponse";
import { ModuleStoreRequest } from "@/models/module/ModuleStoreRequest";
import { RouteStoreRequest } from "@/models/route/RouteStoreRequest";
import messages from "@/constants/messages";

@Options({
  components: {},
})
export default class RoutesCreateView extends Vue {
  public name = "";
  public description = "";
  public method = "";
  public path = "";
  public sortSelected = "name";
  public isLoadingTable = true;
  public needleSearch = null;
  public snackbar = false;
  public text = "";
  public timeout = 10000;
  public methodos = ["GET", "POST", "PUT", "DELETE"];
  public messages = {};
  public btn_disabled = false;

  mounted(): void {
    this.messages = messages;
  }
  changeLoading(): void {
    this.isLoadingTable = !this.isLoadingTable;
  }

  closeSnack(): void {
    this.snackbar = false;
    this.$router.push("/routes");
  }

  async store(event: any): Promise<void> {
    const storeRequest: RouteStoreRequest = {
      name: this.name,
      description: this.description,
      path: this.path,
      method_route: this.method,
      isEnable: false,
    };

    try {
      const response = await services.RouteService.storeNewRoute(storeRequest);

      if (response.has_success) {
        this.text = messages.success;
        this.snackbar = true;
        this.btn_disabled = true;
      }
    } catch (error) {
      this.snackbar = true;
      this.text = messages.fail;
    }
  }
}
</script>
<style lang="css">
.default-data-base {
  border: solid #efecec 0.4px;
}

.w-3 {
  width: 10px;
}

.h-3 {
  height: 10px;
}
</style>
@/models/module/ModuleIndexWithPaginateResponse
