import * as services from "@/services";
import { Menu } from "@/models/menu/Menu";
import { ModuleResultsStorage } from "@/models/module/ModuleResultsStorage";
import { ModuleWithFunctionalities } from "@/models/auth/ModuleWithFunctionalities";
import { getFromStorage, saveOnStorage } from "@/utils/localStorage";
import store from "@/store";

export default class Modules {
  public items: Array<Menu> = [];
  public modules: Array<ModuleWithFunctionalities> = [];
  public moduleSelectedId = 0;

  constructor() {
    const recoveryModules = this.recoveryModulesFromStorage();
    if (recoveryModules.length > 0) {
      this.modules = recoveryModules;
    }
  }

  async getNameModuleService(module: number): Promise<void> {
    const response = await services.ModuleService.getModule(module);

    this.saveModuleStore(response.results);
  }

  saveModuleStore(results: ModuleResultsStorage): void {
    this.saveModuleChannel(results.channels);
    this.saveModuleName(results.name);
  }

  getModuleid(): string | null {
    return getFromStorage("KEY_MODULE_SELECTED");
  }

  getModuleNameSelected(): string | null {
    return getFromStorage("NAME_MODULE_SELECTED");
  }

  getModules(): string | null {
    return getFromStorage("MODULES");
  }

  saveModuleChannel(channel: string): void {
    saveOnStorage("MODULE_CHANNEL", channel);
    store.commit("SET_MODULE_CHANNEL", channel);
  }

  saveModuleName(name: string): void {
    store.commit("SET_MODULE_NAME", name);
    saveOnStorage("NAME_MODULE_SELECTED", name);
  }

  saveModuleKey(key: number): void {
    store.commit("SET_MODULE_KEY", key);
    saveOnStorage("KEY_MODULE_SELECTED", key);
  }

  saveModules(modules: string): void {
    store.commit("SET_MODULE_LIST", modules);
    saveOnStorage("MODULES", modules);
  }

  recoveryModulesFromStorage(): Array<object> {
    let moduleList = [];
    const possibleStringModules = getFromStorage("MODULES");
    if (possibleStringModules) {
      moduleList = JSON.parse(possibleStringModules);
    }
    return moduleList;
  }
}
